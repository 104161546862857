import React, { useEffect, useState } from "react";
import Preloader from "../../utilities/Preloader/Preloader";
import { GetCarsByStatusId } from "../../endpoint";
import { CarAuctionEnum } from "../../enum";
import AuctionCarList from "./AuctionCarList/AuctionCarList";
import { Empty, Pagination } from "antd";

const UnderConsiderationCars = () => {
  const [isReady, setIsReady] = useState(false);
  const [underConsiderationCars, setConfirmedCars] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();

  useEffect(() => {
    GetCarsByStatusId(CarAuctionEnum.UnderConsideration, currentPage).then(
      (res) => {
        setConfirmedCars(res.data.auctionCars);
        setTotalItems(res.data.total);
        setIsReady(true);
      }
    );
  }, [currentPage]);

  const handlePaginationChange = (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(page);
  };

  return (
    <div className="profile-details">
      <div className="profile-details__title">
        <h2>Under Consideration Cars</h2>
        <Preloader isReady={isReady}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2.5rem",
                marginTop: "3rem",
              }}
              className="bids-list"
            >
              {underConsiderationCars?.length > 0 && (
                <>
                  {underConsiderationCars?.map((data) => {
                    return (
                      <div key={data.id}>
                        <AuctionCarList
                          id={data.id}
                          hp={data.hp}
                          km={data.km}
                          name={data.name}
                          petrol={data.petrol}
                          transmission={data.transmission}
                          registrationDate={data.registrationDate}
                          finishingDateTime={data.finishingDateTime}
                          registrationNumber={data.registrationNumber}
                          country={data.country}
                          vehicleCategory={data.vehicleCategory}
                          isFavorite={data.isFavorite}
                          src={data.carMainImage}
                          setCars={setConfirmedCars}
                          setCarsNo={() => {}}
                          bidAmount={data?.bidAmount}
                          //   showInvoiceButton={true}
                          cars={underConsiderationCars}
                          startingPrice={data?.startingPrice}
                          clientName={data?.clientName}
                          registrationMonth={data.registrationMonth}
                        />
                      </div>
                    );
                  })}
                  <Pagination
                    showSizeChanger={false}
                    defaultCurrent={currentPage}
                    total={totalItems}
                    onChange={handlePaginationChange}
                  />
                </>
              )}

              {underConsiderationCars?.length == 0 && (
                <>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>No auctions have been confirmed.</span>}
                  ></Empty>
                </>
              )}
            </div>
          </div>
        </Preloader>
      </div>
    </div>
  );
};

export default UnderConsiderationCars;
