import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import "./Components/MenuLayout/Menu.css";
import "./assets/arvalCSS/fa.css";
import "./assets/arvalCSS/client-arval.css";
import "./assets/arvalCSS/flag-icons.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
