import React, { useEffect, useState } from "react";
import { AppSettingType } from "../../enum";

function ApiInput({
  htmlFor,
  type,
  placeholder,
  labelText,
  id,
  name,
  className,
  disabled,
  onChange,
  data,
  setData,
}) {
  const [inputData, setInputData] = useState(data.find((el) => el.id === id));
  const [triggerEffect, setTriggerEffect] = useState(false);

  function triggerChange(e) {
    onChange({ type: "EDIT_STATE", target: e, id: id });
    setTriggerEffect((prev) => !prev);
  }

  useEffect(() => {
    setInputData(data.find((el) => el.id === id));
  }, [triggerChange]);

  return (
    <>
      <div className="form-input">
        <label className="input-label" htmlFor={htmlFor}>
          {labelText}
        </label>
        {type != AppSettingType.TextboxNumber && (
          <input
            type="text"
            placeholder={placeholder}
            value={inputData.value}
            id={id}
            onChange={triggerChange}
            name={name}
            className={`input ${inputData.value === "" ? className : ""}`}
            disabled={disabled}
          />
        )}
        {type == AppSettingType.TextboxNumber && (
          <input
            type="number"
            required
            placeholder={placeholder}
            value={inputData.value}
            id={id}
            onChange={triggerChange}
            name={name}
            className={`input ${inputData.value === "" ? className : ""}`}
            disabled={disabled}
          />
        )}
      </div>
    </>
  );
}

export default ApiInput;
