import Input from "../../utilities/Input/Input";

import { IoIosArrowBack } from "react-icons/io";
import "./ForgotPassword.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { UserForgotPassword, webControls } from "../../endpoint";
import { Spin } from "antd";
import { toast } from "react-hot-toast";

function ForgotPassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loader, setLoader] = useState(false);

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
    setEmailError(
      validateEmail(event.target.value)
        ? ""
        : "Please enter a valid email address"
    );
  }

  function handleContinueClick() {
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setLoader(true);
      UserForgotPassword(email).then((res) => {
        if (res.data.success) {
          setLoader(false);
          setEmailError("");
          navigate("forgot-password-confirmation");
        } else {
          setLoader(false);
          toast.error(res.data.message, {
            duration: 3000,
            id: "forgotPwErr",
          });
        }
      });
    }
  }

  return (
    <>
      <div className="login-bg">
        <div className="login">
          <span className="login__company">
            {webControls.isCarmax ? "CARMAX" : "CARMAX-KS"}
          </span>
          <h3 className="login__title">Forgot Password?</h3>
          <p className="forgot-pw--text">
            Enter your email and we'll send you instructions to reset your
            password
          </p>
          <div className="input-error input-error--width">
            <Input
              htmlFor={"email"}
              labelText={"Email"}
              type={"text"}
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              disabled={loader}
            />
            {emailError && <p className="error-message ">{emailError}</p>}
          </div>
          {!loader ? (
            <button onClick={handleContinueClick} className="login__button">
              Send reset link
            </button>
          ) : (
            <Spin />
          )}
          <Link className="back-to-login__button" to="..">
            <IoIosArrowBack /> Back to login
          </Link>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
