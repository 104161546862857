import React from "react";
import Preloader from "../../utilities/Preloader/Preloader";

function Protector({ isLoggedIn, children, error }) {
  return (
    <div>
      {isLoggedIn == undefined && (
        <Preloader
          isReady={isLoggedIn !== undefined}
          minHeight={"47vh"}
        ></Preloader>
      )}
      {isLoggedIn !== undefined && isLoggedIn && children}
      {isLoggedIn !== undefined && !isLoggedIn && error}
    </div>
  );
}

export default Protector;
