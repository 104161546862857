import { Card } from "antd";
import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GetTotalSalesByQuantity } from "../../../endpoint";

const SoldCarsAmount = ({}) => {
  const [data, setData] = useState();

  useEffect(() => {
    GetTotalSalesByQuantity().then((res) => {
      setData(res.data);
    });
  }, []);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const formatLegendText = (value) => {
    return capitalizeFirstLetter(value?.split(/(?=[A-Z])/)?.join(" "));
  };

  return (
    <Card title="Total Sales Amount per Month" bordered={false}>
      <div style={{ width: "100%" }}>
        <ResponsiveContainer
          width="100%"
          height={window.innerWidth < 576 ? 300 : 400}
        >
          <LineChart
            width={500}
            height={200}
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            {/* <Tooltip
              formatter={(value, name) => [
                value,
                capitalizeFirstLetter(name.split(/(?=[A-Z])/).join(" ")),
              ]}
            /> */}
            <Tooltip
              formatter={(value, name) => [
                `${capitalizeFirstLetter(
                  name.split(/(?=[A-Z])/).join(" ")
                )}: ${value}`,
              ]}
            />

            <Legend formatter={formatLegendText} />
            <Line
              connectNulls
              type="monotone"
              dataKey="previousYear"
              stroke="#8884d8"
            />
            <Line
              connectNulls
              type="monotone"
              dataKey="currentYear"
              fill="#82ca9d"
              stroke="#82ca9d"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default SoldCarsAmount;
