import React, { useEffect, useState } from "react";
import Input from "../../utilities/Input/Input";
import "./ClientDetails.css";
import { useParams } from "react-router-dom";
import {
  AproveClient,
  DeleteClient,
  GetClientsDetails,
  RefuseClient,
  UpdateClient,
} from "../../endpoint";
import { ClientDocumentType, ClientStatusEnum } from "../../enum";
import DocumentPreview from "./DocumentPreview";
import { Button, Modal, Popconfirm, Spin } from "antd";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CountrySelect from "../Profile/CountrySelect";
import HowDidYouHearSelect from "./HowDidYouHearSelect";
import TypeOfActivitySelect from "./TypeOfActivitySelect";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";
import TextArea from "antd/es/input/TextArea";

function getDocumentName(type) {
  if (type === ClientDocumentType.RegistrationDocument) {
    return "Company registration document";
  }
  if (type === ClientDocumentType.IdentificationDocument) {
    return "Identification document of company manager/owner (passport/ID card)";
  }

  if (type === ClientDocumentType.ConfirmationDocument) {
    return "Documents, confirming, that the company is a car trader";
  }
  if (type === ClientDocumentType.PaymentProofProfature) {
    return "Proof of the payment of the profature";
  }
  if (type === ClientDocumentType.VATRegistrationDocument) {
    return "VAT registration document";
  }
  if (type === ClientDocumentType.IdentificationDocumentOfAuthorizedPerson) {
    return "Identity document of authorized person (passport/ID card)";
  }
  if (type === ClientDocumentType.AttorneyToActOnBehalfDocument) {
    return "Power of attorney to act on behalf of the company";
  }
}
function ClientDetails() {
  const { clientDetailsId } = useParams();

  const navigate = useNavigate();

  const [editable, setEditable] = useState(false);
  const [editableInput, setEditableInput] = useState("");
  const [editableError, setEditableError] = useState("");

  const handleEditable = () => {
    setEditable(true);
    setEditableInput("input-editable");
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedActivity, setSelectedActivity] = useState("");

  const [clientDetails, setClientDetails] = useState({
    name: "",
    firstName: "",
    lastName: "",
    companyRegistrationNumber: "",
    mobileNo: "",
    countryCode: "",
    howDidYouHear: "",
    typeofActivity: "",
    city: "",
    zipCode: "",
    address: "",
    vatRegistrationNumber: "",
    maxOpenBids: "",
    comment: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [preloader1, setPreloader1] = useState(true);
  const [refusePreloader, setRefusePreloader] = useState(false);
  const [aprovePreloader, setAprovePreloader] = useState(false);

  const [refuseInput, setRefuseInput] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    GetClientsDetails(clientDetailsId).then((res) => {
      setClientDetails((prevDetails) => ({
        ...res.data,
        minAuctionFee:
          res.data.minAuctionFee !== null ? res.data.minAuctionFee : "",
        maxAuctionFee:
          res.data.maxAuctionFee !== null ? res.data.maxAuctionFee : "",
      }));
      setPreloader1(false);
    });
  }, [clientDetailsId]);

  const handleRefuseInputChange = (e) => {
    setRefuseInput(e.target.value);
  };

  const handleAproveClient = () => {
    setAprovePreloader(true);
    AproveClient(clientDetailsId, clientDetails.maxOpenBids).then((res) => {
      if (res.data.success) {
        setAprovePreloader(false);
        navigate("/dashboard/clients-list");
        toast.success(res.data.message, {
          duration: 2000,
          id: "aprovedSuccessfuly",
        });
      }
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleRefuseClient = () => {
    if (refuseInput === "") {
      setError("textarea-red");
      toast.error("Input cannot be empty!", {
        duration: 2000,
        id: "textareaEmpty",
      });
    } else {
      setRefusePreloader(true);
      RefuseClient(clientDetailsId, refuseInput).then((res) => {
        if (res.data.success) {
          navigate("/dashboard/clients-list");
          setIsModalOpen(false);
          setRefusePreloader(false);
          toast.success(res.data.message, {
            duration: 2000,
            id: "refusedSuccessfuly",
          });
        }
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    if (
      clientDetails.name === "" ||
      clientDetails.firstName === "" ||
      clientDetails.lastName === "" ||
      clientDetails.companyRegistrationNumber === "" ||
      clientDetails.mobileNo === "" ||
      clientDetails.countryCode === "" ||
      clientDetails.city === "" ||
      clientDetails.zipCode === "" ||
      clientDetails.address === "" ||
      selectedActivity === 0
    ) {
      setEditableError("input-red");
      toast.error("Inputs cannot be empty!", {
        duration: 2000,
        id: "inputsCannotBeEmpty",
      });
      return;
    } else if (
      (clientDetails.minAuctionFee !== "" ||
        clientDetails.maxAuctionFee !== "") &&
      (clientDetails.minAuctionFee === "" || clientDetails.maxAuctionFee === "")
    ) {
      setEditableError("input-red");
      toast.error("Both Max and Min Auction service fee should be filled!", {
        duration: 2000,
        id: "inputsCannotBeEmpty",
      });
    } else {
      setEditable(false);
      UpdateClient({
        id: clientDetails.id,
        name: clientDetails.name,
        firstName: clientDetails.firstName,
        lastName: clientDetails.lastName,
        companyRegistrationNumber: clientDetails.companyRegistrationNumber,
        mobileNo: clientDetails.mobileNo,
        countryCode: selectedCountry,
        city: clientDetails.city,
        zipCode: clientDetails.zipCode,
        address: clientDetails.address,
        howDidYouHear: selectedOption,
        typeofActivity: selectedActivity,
        vatRegistrationNumber: clientDetails.vatRegistrationNumber,
        maxOpenBids:
          clientDetails.maxOpenBids === "" ? null : clientDetails.maxOpenBids,
        minAuctionFee:
          clientDetails.minAuctionFee === ""
            ? null
            : clientDetails.minAuctionFee,
        maxAuctionFee:
          clientDetails.maxAuctionFee === ""
            ? null
            : clientDetails.maxAuctionFee,
        comment: clientDetails.comment == "" ? null : clientDetails.comment,
      }).then((res) => {
        if (res.data.success) {
          navigate("/dashboard/clients-list");
          toast.success("Client is successfuly updated", {
            duration: 2000,
            id: "clientUpdatedSuccessfuly",
          });
        }
      });
    }
  };

  const handleCancelEdit = () => {
    setEditable(false);

    GetClientsDetails(clientDetailsId).then((res) => {
      setClientDetails(res.data);
      setSelectedCountry(clientDetails.countryCode);
      setSelectedOption(clientDetails.howDidYouHear);
      setSelectedActivity(clientDetails.typeofActivity);
      setPreloader1(false);
    });
  };

  return (
    <div>
      {!preloader1 && (
        <div className="profile-details clients-details">
          <div className="profile-details__title client-details--title">
            <h2>Client Details</h2>
            <div style={{ display: "flex", gap: "1rem" }}>
              {editable && (
                <>
                  <Button onClick={handleSave} type="primary">
                    Save
                  </Button>
                  <Button onClick={handleCancelEdit}>Cancel</Button>
                </>
              )}
              {!editable && <Button onClick={handleEditable}>Edit</Button>}
              {!editable && (
                <Popconfirm
                  title={"Delete Client"}
                  description={"Are you sure you want to delete this client?"}
                  okText={"Yes"}
                  cancelText={"No"}
                  onConfirm={() => {
                    DeleteClient(clientDetails?.id).then((res) => {
                      if (res.data.success) {
                        toast.success(res.data.message, {
                          duration: 2000,
                          id: "appSuccessfullyUpdated",
                        });
                        navigate("/dashboard/clients-list");
                      } else {
                        toast.error(res.data.message, {
                          duration: 3000,
                          id: "forgotPwErr",
                        });
                      }
                    });
                  }}
                >
                  <Button type="primary" danger ghost>
                    Delete
                  </Button>
                </Popconfirm>
              )}
            </div>
          </div>
          <div className="profile-details__inputs">
            <div className="profile-details__input-content">
              <Input
                htmlFor={"company"}
                labelText={
                  <>
                    Company <RequiredStar />
                  </>
                }
                type={"text"}
                id="company"
                value={clientDetails.name}
                disabled={editable ? false : true}
                className={
                  editable
                    ? clientDetails.name !== ""
                      ? editableInput
                      : clientDetails.name === ""
                      ? editableError
                      : ""
                    : ""
                }
                name="name"
                onChange={handleInputChange}
              />
              <Input
                htmlFor={"companyRegistrationNumber"}
                labelText={
                  <>
                    Company Registration Number <RequiredStar />
                  </>
                }
                type={"text"}
                id="companyRegistrationNumber"
                value={clientDetails.companyRegistrationNumber}
                disabled={editable ? false : true}
                className={
                  editable
                    ? clientDetails.companyRegistrationNumber !== ""
                      ? editableInput
                      : clientDetails.companyRegistrationNumber === ""
                      ? editableError
                      : ""
                    : ""
                }
                name="companyRegistrationNumber"
                onChange={handleInputChange}
              />
            </div>
            <div className="profile-details__input-content">
              <Input
                htmlFor={"vatRegistrationNumber"}
                labelText={"VAT Registration Number"}
                type={"text"}
                id="vatRegistrationNumber"
                value={clientDetails.vatRegistrationNumber}
                disabled={editable ? false : true}
                className={editable ? editableInput : ""}
                name="vatRegistrationNumber"
                onChange={handleInputChange}
              />
              <TypeOfActivitySelect
                defaultValue={clientDetails.typeofActivity}
                value={selectedActivity}
                setSelectedActivity={setSelectedActivity}
                className={
                  editable
                    ? selectedActivity !== 0
                      ? "country-border"
                      : selectedActivity === 0
                      ? "input-empty-red"
                      : ""
                    : ""
                }
                disabled={!editable}
                required={true}
              />
            </div>
            <div className="profile-details__input-content">
              <Input
                htmlFor={"firstName"}
                labelText={
                  <>
                    First Name <RequiredStar />
                  </>
                }
                type={"text"}
                id="firstName"
                value={clientDetails.firstName}
                disabled={editable ? false : true}
                className={
                  editable
                    ? clientDetails.firstName !== ""
                      ? editableInput
                      : clientDetails.firstName === ""
                      ? editableError
                      : ""
                    : ""
                }
                name="firstName"
                onChange={handleInputChange}
              />
              <Input
                htmlFor={"lastName"}
                labelText={
                  <>
                    Last Name <RequiredStar />
                  </>
                }
                type={"text"}
                id="lastName"
                value={clientDetails.lastName}
                disabled={editable ? false : true}
                className={
                  editable
                    ? clientDetails.lastName !== ""
                      ? editableInput
                      : clientDetails.lastName === ""
                      ? editableError
                      : ""
                    : ""
                }
                name="lastName"
                onChange={handleInputChange}
              />
            </div>
            <div className="profile-details__input-content">
              <Input
                htmlFor={"email"}
                labelText={"Email"}
                type={"email"}
                id="email"
                value={clientDetails.email}
                disabled={true}
              />
              <Input
                htmlFor={"phoneNum"}
                labelText={
                  <>
                    Phone Number <RequiredStar />
                  </>
                }
                type={"tel"}
                id="phoneNum"
                value={clientDetails.mobileNo}
                disabled={editable ? false : true}
                className={
                  editable
                    ? clientDetails.mobileNo !== ""
                      ? editableInput
                      : clientDetails.mobileNo === ""
                      ? editableError
                      : ""
                    : ""
                }
                name="mobileNo"
                onChange={handleInputChange}
              />
            </div>

            <div className="profile-details__input-content">
              <CountrySelect
                defaultValue={clientDetails.countryCode}
                value={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                className={editable ? "country-border" : ""}
                disabled={!editable}
                required={true}
              />
              <Input
                htmlFor={"city"}
                labelText={
                  <>
                    City <RequiredStar />
                  </>
                }
                type={"text"}
                id="city"
                value={clientDetails.city}
                disabled={editable ? false : true}
                className={
                  editable
                    ? clientDetails.city !== ""
                      ? editableInput
                      : clientDetails.city === ""
                      ? editableError
                      : ""
                    : ""
                }
                name="city"
                onChange={handleInputChange}
              />
            </div>

            <div className="profile-details__input-content">
              <Input
                htmlFor={"zipCode"}
                labelText={
                  <>
                    Zip Code <RequiredStar />
                  </>
                }
                type={"text"}
                id="zipCode"
                value={clientDetails.zipCode}
                disabled={editable ? false : true}
                className={
                  editable
                    ? clientDetails.zipCode !== ""
                      ? editableInput
                      : clientDetails.zipCode === ""
                      ? editableError
                      : ""
                    : ""
                }
                name="zipCode"
                onChange={handleInputChange}
              />
              <Input
                htmlFor={"address"}
                labelText={
                  <>
                    Address <RequiredStar />
                  </>
                }
                type={"text"}
                id="address"
                value={clientDetails.address}
                disabled={editable ? false : true}
                className={
                  editable
                    ? clientDetails.address !== ""
                      ? editableInput
                      : clientDetails.address === ""
                      ? editableError
                      : ""
                    : ""
                }
                name="address"
                onChange={handleInputChange}
              />
            </div>

            <div className="profile-details__input-content">
              <Input
                htmlFor={"maxOpenBids"}
                labelText={"Max Open Bids (Leave empty for unlimited bids)"}
                type={"number"}
                id="maxOpenBids"
                value={clientDetails.maxOpenBids}
                disabled={
                  clientDetails.statusId === 1 ? false : editable ? false : true
                }
                className={editable ? editableInput : ""}
                name="maxOpenBids"
                onChange={handleInputChange}
              />
              <HowDidYouHearSelect
                defaultValue={clientDetails.howDidYouHear}
                value={selectedOption}
                setSelectedOption={setSelectedOption}
                className={editable ? "country-border" : ""}
                disabled={!editable}
              />
            </div>

            <div className="profile-details__input-content">
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  gap: "1rem",
                  marginBottom: "auto",
                }}
              >
                <Input
                  htmlFor={"minAuctionFee"}
                  labelText={"Min Auction service fee (<20k)"}
                  type={"number"}
                  id="minAuctionFee"
                  value={clientDetails.minAuctionFee}
                  disabled={editable ? false : true}
                  className={editable ? editableInput : ""}
                  name="minAuctionFee"
                  onChange={handleInputChange}
                />
                <Input
                  htmlFor={"maxAuctionFee"}
                  labelText={"Max Auction service fee (>20k)"}
                  type={"number"}
                  id="maxAuctionFee"
                  value={clientDetails.maxAuctionFee}
                  disabled={editable ? false : true}
                  className={editable ? editableInput : ""}
                  name="maxAuctionFee"
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-input">
                <label className="input-label">Comment</label>
                <TextArea
                  rows={1}
                  name="comment"
                  style={{ minHeight: "44px" }}
                  disabled={editable ? false : true}
                  value={clientDetails.comment}
                  onChange={handleInputChange}
                  className={`client-comment ${editable ? editableInput : ""}`}
                />
              </div>
            </div>
            {clientDetails.clientDocuments.registrationDocuments.length > 0 && (
              <div className="profile-details__input-content  profile-details__document--section">
                <label className="modal-label" htmlFor={document.id}>
                  {getDocumentName(ClientDocumentType.RegistrationDocument)}
                </label>
                <DocumentPreview
                  name={getDocumentName(
                    ClientDocumentType.RegistrationDocument
                  )}
                  documents={
                    clientDetails.clientDocuments.registrationDocuments
                  }
                />
              </div>
            )}

            {clientDetails.clientDocuments.identificationDocuments.length >
              0 && (
              <div className="profile-details__input-content  profile-details__document--section ">
                <label className="modal-label" htmlFor={document.id}>
                  {getDocumentName(ClientDocumentType.IdentificationDocument)}
                </label>
                <DocumentPreview
                  name={getDocumentName(
                    ClientDocumentType.IdentificationDocument
                  )}
                  documents={
                    clientDetails.clientDocuments.identificationDocuments
                  }
                />
              </div>
            )}
            {clientDetails.clientDocuments.confirmationDocuments.length > 0 && (
              <div className="profile-details__input-content  profile-details__document--section ">
                <label className="modal-label" htmlFor={document.id}>
                  {getDocumentName(ClientDocumentType.ConfirmationDocument)}
                </label>
                <DocumentPreview
                  name={getDocumentName(
                    ClientDocumentType.ConfirmationDocument
                  )}
                  documents={
                    clientDetails.clientDocuments.confirmationDocuments
                  }
                />
              </div>
            )}
            {clientDetails.clientDocuments.vatRegistrationDocuments.length >
              0 && (
              <div className="profile-details__input-content  profile-details__document--section ">
                <label className="modal-label" htmlFor={document.id}>
                  {getDocumentName(ClientDocumentType.VATRegistrationDocument)}
                </label>
                <DocumentPreview
                  name={getDocumentName(
                    ClientDocumentType.VATRegistrationDocument
                  )}
                  documents={
                    clientDetails.clientDocuments.vatRegistrationDocuments
                  }
                />
              </div>
            )}
            {clientDetails.clientDocuments.paymentProofProfature.length > 0 && (
              <div className="profile-details__input-content  profile-details__document--section ">
                <label className="modal-label" htmlFor={document.id}>
                  {getDocumentName(ClientDocumentType.PaymentProofProfature)}
                </label>
                <DocumentPreview
                  name={getDocumentName(
                    ClientDocumentType.PaymentProofProfature
                  )}
                  documents={
                    clientDetails.clientDocuments.paymentProofProfature
                  }
                />
              </div>
            )}
            {clientDetails.clientDocuments
              .identificationDocumentsOfAuthorizedPerson.length > 0 && (
              <div className="profile-details__input-content  profile-details__document--section ">
                <label className="modal-label" htmlFor={document.id}>
                  {getDocumentName(
                    ClientDocumentType.IdentificationDocumentOfAuthorizedPerson
                  )}
                </label>
                <DocumentPreview
                  name={getDocumentName(
                    ClientDocumentType.IdentificationDocumentOfAuthorizedPerson
                  )}
                  documents={
                    clientDetails.clientDocuments
                      .identificationDocumentsOfAuthorizedPerson
                  }
                />
              </div>
            )}
            {clientDetails.clientDocuments.attorneyToActOnBehalfDocuments
              .length > 0 && (
              <div className="profile-details__input-content  profile-details__document--section ">
                <label className="modal-label" htmlFor={document.id}>
                  {getDocumentName(
                    ClientDocumentType.AttorneyToActOnBehalfDocument
                  )}
                </label>
                <DocumentPreview
                  name={getDocumentName(
                    ClientDocumentType.AttorneyToActOnBehalfDocument
                  )}
                  documents={
                    clientDetails.clientDocuments.attorneyToActOnBehalfDocuments
                  }
                />
              </div>
            )}
          </div>
          {(clientDetails.statusId === ClientStatusEnum.Pending ||
            clientDetails.statusId === ClientStatusEnum.DocumentsNotUpload) && (
            <div className="profile-details__buttons">
              <button className="save-changes" onClick={handleAproveClient}>
                {aprovePreloader ? (
                  <Spin
                    className="aprove-spin"
                    style={{ justifyContent: "center", color: "#fff" }}
                    size="small"
                  />
                ) : (
                  "Aprove"
                )}
              </button>

              <button className="discard refuse" onClick={showModal}>
                Refuse
              </button>

              <Modal
                title="Refuse Client"
                open={isModalOpen}
                onOk={handleRefuseClient}
                onCancel={handleCancel}
                centered={true}
                okText="Refuse"
                className="refuse-modal"
              >
                {refusePreloader ? (
                  <Spin style={{ justifyContent: "center" }} size="large" />
                ) : (
                  <textarea
                    className={`contact-form__textarea ${
                      refuseInput === "" ? error : ""
                    }`}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    value={refuseInput}
                    onChange={handleRefuseInputChange}
                    placeholder="Write a reject message"
                    style={{ width: "100%" }}
                  ></textarea>
                )}
              </Modal>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ClientDetails;
