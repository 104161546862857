import React from "react";

import { IoIosArrowBack } from "react-icons/io";
import "./ForgotPasswordConfirmation.css";
import { Link } from "react-router-dom";
import { webControls } from "../../endpoint";

function ForgotPasswordConfirmation() {
  return (
    <>
      <div className="login-bg">
        <div className="login login--padding">
          <span className="login__company">
            {webControls.isCarmax ? "CARMAX" : "CARMAX-KS"}
          </span>
          <h3 className="login__title">Forgot Password Confirmation</h3>
          <p>Please check your email to reset your password.</p>
          <Link className="back-to-login__link" to="/">
            <IoIosArrowBack /> Back To Login
          </Link>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordConfirmation;
