import React from "react";

function RequiredStar() {
  return (
    <span style={{ color: "#d00", fontWeight: 300, paddingLeft: "5px" }}>
      *
    </span>
  );
}

export default RequiredStar;
