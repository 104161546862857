import React, { useState } from "react";
import Input from "../../utilities/Input/Input";

import CountrySelect from "../Profile/CountrySelect";

import { useNavigate } from "react-router-dom";
import { Checkbox } from "antd";

import { AddServiceAxios } from "../../endpoint";
import "./AddServices.css";
import { toast } from "react-hot-toast";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function AddServices() {
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState("");

  const [checkbox, setCheckbox] = useState({
    standardPassengerCar: false,
    minivan: false,
    SUV: false,
  });

  const [selectedCountry, setSelectedCountry] = useState("");

  const navigate = useNavigate();

  const handleCancle = () => {
    navigate("/dashboard/services");
  };

  function handleCheckboxChange(e) {
    const { name, checked } = e.target;
    setCheckbox((prevCheckbox) => ({
      ...prevCheckbox,
      [name]: checked,
    }));
  }

  const handleChange = (e) => {
    setPrice(e.target.value);
  };

  const handleClick = () => {
    let formIsValid = true;

    if (price === "" || selectedCountry === "Choose Country") {
      toast.error("Inputs cannot be empty!", {
        duration: 2000,
        id: "priceInputField",
      });
      setPriceError("input-empty-red");
      formIsValid = false;
    } else {
      setPriceError("");
      formIsValid = true;
    }

    if (formIsValid) {
      AddServiceAxios({
        price: price,
        standardPassengerCar: checkbox.standardPassengerCar,
        minivan: checkbox.minivan,
        suv: checkbox.SUV,
        countryCode: selectedCountry,
      }).then((res) => {
        if (res.data.success) {
          navigate("/dashboard/services");
          toast.success(res.data.message, {
            duration: 2000,
            id: "serviceAddedSuccessfuly",
          });
        }
      });
    }
  };

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title">
          <h2>Add Service</h2>
        </div>
        <div className="profile-details__inputs">
          <div className="profile-details__input-content">
            <CountrySelect
              defaultValue={"Choose Country"}
              value={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              className={selectedCountry === "Choose Country" ? priceError : ""}
              required={true}
            />
            <Input
              htmlFor={"price"}
              labelText={
                <>
                  Price <RequiredStar />
                </>
              }
              type={"number"}
              id="price"
              value={price}
              onChange={handleChange}
              className={price === "" ? priceError : ""}
            />
          </div>
          <div className="profile-details__input-content services__checkboxes">
            <Checkbox
              onChange={handleCheckboxChange}
              checked={checkbox.standardPassengerCar}
              name="standardPassengerCar"
            >
              Standard Passenger Car
            </Checkbox>
            <Checkbox
              onChange={handleCheckboxChange}
              checked={checkbox.minivan}
              name="minivan"
            >
              Minivan
            </Checkbox>
            <Checkbox
              onChange={handleCheckboxChange}
              checked={checkbox.SUV}
              name="SUV"
            >
              SUV / Off-road
            </Checkbox>
          </div>
        </div>
        <div className="profile-details__buttons">
          <button className="save-changes" onClick={handleClick}>
            Add Service
          </button>
          <button onClick={handleCancle} className="discard">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default AddServices;
