import { BsWalletFill } from "react-icons/bs";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { GoInfo } from "react-icons/go";
import { BsInfoSquare } from "react-icons/bs";
import { FaCarCrash, FaHistory } from "react-icons/fa";
import { TiSocialDribbbleCircular } from "react-icons/ti";
import "./CarDetails.css";
import GalleryCarousel from "./GalleryCarousel";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import Protector from "../../Protector/Protector";
import { useParams } from "react-router-dom";
import { ActiveAuctionCarDetails, GetPriceList } from "../../../endpoint";
import { Skeleton } from "antd";
import DamagesCarousel from "./DamagesCarousel";
import { AuctionStatusEnum } from "../../../enum";

function CarDetails() {
  const [showFees, setShowFees] = useState(false);
  const [carDetails, setCarDetails] = useState();
  const [isReady, setIsReady] = useState(false);
  const [bidValue, setBidValue] = useState(0);
  const [transportationCost, setTransportationCost] = useState();
  const [bankFee, setBankFee] = useState();
  const [serviceFee, setServiceFee] = useState();
  const isLoggedIn = useContext(AuthContext);
  const [sliderImagesDamages, setSliderImagesDamages] = useState([]);
  const [showSlider, setShowSlider] = useState(false);
  const [modifiedSliderURLs, setModifiedSliderURLs] = useState([]);
  const [modifiedDamagesURLs, setModifiedDamagesURLs] = useState([]);
  const [modifiedFinishedDate, setModifiedFinishedDate] = useState();

  const currentTime = new Date();
  // const baseImgUrl = process.env.REACT_APP_IMAGE_URL;
  const { id } = useParams();

  useEffect(() => {
    ActiveAuctionCarDetails(id).then((res) => {
      const carDetails = res.data;

      const finishingDateTime = new Date(carDetails.finishingDateTime);
      setModifiedFinishedDate(finishingDateTime);

      if (!isNaN(finishingDateTime)) {
        const updatedDay = String(finishingDateTime.getDate()).padStart(2, "0");
        const updatedMonth = String(finishingDateTime.getMonth() + 1).padStart(
          2,
          "0"
        );
        const updatedYear = finishingDateTime.getFullYear();
        const updatedHours = String(finishingDateTime.getHours()).padStart(
          2,
          "0"
        );
        const updatedMinutes = String(finishingDateTime.getMinutes()).padStart(
          2,
          "0"
        );

        const updatedFinishingDateTime = `${updatedMonth}/${updatedDay}/${updatedYear} ${updatedHours}:${updatedMinutes}`;

        const updatedCarDetails = {
          ...carDetails,
          finishingDateTime: updatedFinishingDateTime,
        };

        setCarDetails(updatedCarDetails);
        setModifiedSliderURLs(
          updatedCarDetails?.sliderImages?.map(
            (url) => `${url.replace(/\\/g, "/")}`
          )
        );
        // setModifiedDamagesURLs(
        //   updatedCarDetails?.damageImages?.map(
        //     (url) => `${baseImgUrl}${url.replace(/\\/g, "/")}`
        //   )
        // );
        setModifiedDamagesURLs(
          updatedCarDetails?.damageImages?.map((url) => {
            if (url?.includes("motortrade-no-photo.svg")) {
              return "https://remktg.arval.com/buyer/assets/images/motortrade/motortrade-no-photo.svg";
            } else {
              return `${url.replace(/\\/g, "/")}`;
            }
          })
        );
        setBidValue(carDetails?.bidAmount);
        setIsReady(true);
      } else {
        console.error(
          "Invalid finishingDateTime:",
          carDetails.finishingDateTime
        );
      }
    });
    GetPriceList().then((res) => {
      setTransportationCost(res.data.transport);
      setBankFee(res.data.bankFee);
      setServiceFee(res.data.auctionServiceFee);
    });
  }, []);

  const handleClick = () => {
    setShowFees(!showFees);
  };

  useEffect(() => {
    const carDamagesDiv = document.querySelector(".car-damages");
    if (carDamagesDiv) {
      const htmlContent = carDetails?.damages;

      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = htmlContent;

      const imgElements = tempContainer.querySelectorAll("img.img-fluid");

      imgElements.forEach((img, index) => {
        if (index < modifiedDamagesURLs.length) {
          img.src = modifiedDamagesURLs[index];
        }
      });
      carDamagesDiv.innerHTML = tempContainer.innerHTML;
    }

    const elements = document.querySelectorAll(".swiper-slide");
    elements.forEach((element) => {
      element.addEventListener("click", () => {
        const parent = element.closest(".swiper-wrapper");
        const imgElements = parent.querySelectorAll("img.img-fluid");

        const imageSrcs = Array.from(imgElements)?.map((img) => img.currentSrc);
        setSliderImagesDamages(imageSrcs);
        setShowSlider(true);
      });
    });

    const showAllDamageImagesEl = document.querySelector(".car-damages u");
    showAllDamageImagesEl?.addEventListener("click", () => {
      setSliderImagesDamages(modifiedDamagesURLs);
      setShowSlider(true);
    });
  }, [modifiedDamagesURLs]);

  return (
    <Protector
      isLoggedIn={isLoggedIn.isLoggedIn}
      error={
        <div className="my-bids-error">You don't have access to this path!</div>
      }
    >
      <div className="car-details-bg">
        <div className="">
          {isReady ? (
            <div className="car-details-container">
              <div className="car-details-container__header">
                <p>
                  <BsWalletFill />{" "}
                  {currentTime > modifiedFinishedDate ? "Ended on" : "Ends on"}:{" "}
                  {carDetails?.finishingDateTime?.substring(0, 10)}
                </p>
              </div>
              <div className="car-details">
                <div className="car-details-title">
                  <h2>{carDetails?.name}</h2>
                  <div className="car-details-title__img">
                    <GalleryCarousel sliderImages={modifiedSliderURLs} />
                  </div>
                  <div className="inline-links">
                    <span>
                      <BsInfoSquare size={17} style={{ marginRight: "5px" }} />{" "}
                      <a href="#car-details" className="text-dark">
                        Details
                      </a>
                    </span>
                    <span>
                      <FaCarCrash size={20} style={{ marginRight: "5px" }} />
                      <a href="#damages" className="text-dark">
                        Damages
                      </a>
                    </span>
                    <span>
                      <FaHistory size={17} style={{ marginRight: "5px" }} />
                      <a href="#maintenance-history" className="text-dark">
                        Maintenance History
                      </a>
                    </span>
                    <span>
                      <TiSocialDribbbleCircular
                        size={20}
                        style={{ marginRight: "5px" }}
                      />
                      <a href="#tyres" className="text-dark">
                        Tyres
                      </a>
                    </span>
                  </div>
                </div>
                <div className="car-details-info">
                  {carDetails?.auctionStatus == AuctionStatusEnum.inPreview ? (
                    <div className="vat-excluded">VAT excluded</div>
                  ) : (
                    <div className="car-details-info__inputAndButton">
                      {(carDetails?.startingPrice ||
                        carDetails?.startingPrice !== 0) && (
                        <span className="starting-price">
                          Start Price:{" "}
                          <span>
                            {" "}
                            {carDetails?.startingPrice?.toLocaleString()}€
                          </span>
                        </span>
                      )}
                      <span className="max-bid-amount">
                        <GoInfo /> Highest Bid:{" "}
                        {carDetails?.bidAmount
                          ? `${carDetails?.bidAmount?.toLocaleString()}€`
                          : "N/A"}
                      </span>
                    </div>
                  )}

                  <div className="car-details-fee">
                    <div
                      className="car-details-fee__title"
                      onClick={handleClick}
                    >
                      Show all fees excluding VAT{" "}
                      {!showFees ? <AiOutlineRight /> : <AiOutlineDown />}
                    </div>
                    <div
                      className={`grid-control ${
                        showFees ? "grid-expand" : ""
                      }`}
                    >
                      <div className="car-details-fee__container">
                        <div className="car-details-fee__content">
                          <span>Vehicle</span>
                          <span>€ {bidValue}</span>
                        </div>
                        <div className="car-details-fee__content">
                          <span className="width-fix">
                            Transportation cost to delivery location
                          </span>
                          <span>€ {transportationCost}</span>
                        </div>
                        <div className="car-details-fee__content">
                          <span>Bank fee</span>
                          <span>€ {bankFee}</span>
                        </div>
                        <span className="car-details-fee__line"></span>
                        <div className="car-details-fee__content fee-content__total">
                          <span>Sub total:</span>
                          <span>
                            €{" "}
                            {parseInt(bidValue) +
                              parseInt(transportationCost) +
                              parseInt(bankFee)}
                          </span>
                        </div>
                        <div className="car-details-fee__content">
                          <span>Auction service fee*</span>
                          <span>
                            € {bidValue < 10000 ? serviceFee : bidValue * 0.03}
                          </span>
                        </div>
                        <span className="car-details-fee__line"></span>
                        <div className="car-details-fee__content fee-content__total">
                          <span>Total excluding VAT:</span>
                          <span>
                            €{" "}
                            {parseInt(bidValue) +
                              parseInt(transportationCost) +
                              parseInt(bankFee) +
                              (bidValue < 10000 ? serviceFee : bidValue * 0.03)}
                          </span>
                        </div>
                        <p>
                          *The auction service fee includes the operator fee,
                          auction fee, vehicle and document preparation
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="car-details-info-about-car">
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">Brand</span>
                      <span>{carDetails?.brand}</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>

                    <div className="car-details-info-about-car__content">
                      <span className="details-title">
                        First registration date
                      </span>
                      <span>
                        {" "}
                        {carDetails?.registrationMonth},{" "}
                        {carDetails?.registrationDate}
                      </span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">Fuel</span>
                      <span>{carDetails?.petrol}</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">Gearbox</span>
                      <span>{carDetails?.transmission}</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">Engine capacity</span>
                      <span>
                        {carDetails?.engineCapacity?.toLocaleString()}{" "}
                        <span style={{ whiteSpace: "nowrap" }}>
                          cm<sup style={{ fontSize: "0.6rem" }}>3</sup>
                        </span>
                      </span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">Power</span>
                      <span>{carDetails?.hp?.toLocaleString()} KW</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">Mileage </span>
                      <span>{carDetails?.km?.toLocaleString()} km</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">CO2</span>
                      <span>{carDetails?.cO2Emission?.toLocaleString()}</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">
                        Green Gas Emission Class
                      </span>
                      <span>{carDetails?.greenhouseGasEmissionClass}</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">VIN number</span>
                      <span>{carDetails?.vinNumber}</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">Seats</span>
                      <span>{carDetails?.seats}</span>
                    </div>
                    <span className="car-details-info-about-car__content-line"></span>
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">Keys No</span>
                      <span>{carDetails?.noKeys}</span>
                    </div>
                  </div>
                </div>
              </div>
              {showSlider && (
                <DamagesCarousel
                  sliderImages={sliderImagesDamages}
                  showSlider={showSlider}
                  setShowSlider={setShowSlider}
                />
              )}
              <div
                className="car--details"
                id="car-details"
                dangerouslySetInnerHTML={{
                  __html: carDetails?.details,
                }}
              ></div>
              <div
                id="damages"
                className="car-damages"
                dangerouslySetInnerHTML={{
                  __html: carDetails?.damages,
                }}
              ></div>
              <div
                id="maintenance-history"
                className="car-maintance-history"
                dangerouslySetInnerHTML={{
                  __html: carDetails?.maintanceHistory,
                }}
              ></div>
              <div
                id="tyres"
                className="car-damages"
                dangerouslySetInnerHTML={{
                  __html: carDetails?.tyres,
                }}
              ></div>
            </div>
          ) : (
            <div className="container details-skeleton-containers ">
              <div
                className="auction-list__content"
                style={{ padding: "2rem 0" }}
              >
                <Skeleton active paragraph={false} />
                <div>
                  <Skeleton.Button active={true} size={256} shape={"square"} />
                  <Skeleton
                    active
                    paragraph={{
                      rows: 13,
                    }}
                    title={false}
                  />
                </div>
                <Skeleton
                  active
                  paragraph={{
                    rows: 5,
                  }}
                  title={false}
                />
                <Skeleton
                  active
                  paragraph={{
                    rows: 5,
                  }}
                  title={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Protector>
  );
}

export default CarDetails;
