import React, { useState } from "react";
import Input from "../../utilities/Input/Input";

import "./AddUser.css";

import { useNavigate } from "react-router-dom";
import { AddUserAxios, CheckIfUserEmailExist } from "../../endpoint";
import UserRole from "./UserRole";
import { toast } from "react-hot-toast";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function AddUser() {
  const [error, setError] = useState("");
  const [userData, setUserData] = useState({
    username: "",
    firstname: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    city: "",
    address: "",
    role: 0,
  });

  const navigate = useNavigate();

  const handleCancle = () => {
    navigate("/dashboard/users-list");
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  }

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const addUserClick = () => {
    if (
      userData.username === "" ||
      userData.firstname === "" ||
      userData.lastName === "" ||
      userData.email === "" ||
      userData.password === "" ||
      userData.confirmPassword === "" ||
      userData.role === 0
    ) {
      toast.error("Inputs cannot be empty!", {
        duration: 2000,
        id: "inputsCannotBeEmpty",
      });
      setError("input-empty-red");
    } else if (!validateEmail(userData.email)) {
      toast.error("Please enter a valid email address!", {
        duration: 2000,
        id: "eneterAvalidEmailAddress",
      });
      setError("input-empty-red");
    } else if (
      userData.password.length < 6 ||
      !/\W/.test(userData.password) ||
      !/\d/.test(userData.password) ||
      !/[A-Z]/.test(userData.password) ||
      !/[a-z]/.test(userData.password)
    ) {
      setError("input-empty-red");
      toast.error(
        "Passwords must be at least 6 characters long. \n\n Contains at least one non-alphanumeric character , one digit (0-9) and one uppercase letter (A-Z).",
        {
          duration: 7000,
          id: "passwordRequirements",
        }
      );
    } else if (userData.password !== userData.confirmPassword) {
      setError("input-empty-red");
      toast.error("Password do not match!", {
        duration: 2000,
        id: "passwordDoNotMatch",
      });
    } else if (validateEmail(userData.email)) {
      CheckIfUserEmailExist(userData.email).then((res) => {
        if (!res.data.success) {
          toast.error(res.data.message, {
            duration: 2000,
            id: "emailIsTaken",
          });
        } else {
          setError("");
          AddUserAxios(userData).then((res) => {
            if (res.data.success) {
              navigate("/dashboard/users-list");
              toast.success(res.data.message, {
                duration: 2000,
                id: "userAddedSuccessfuly",
              });
            } else {
              toast.error(res.data.message, {
                duration: 3000,
                id: "errorAddUser",
              });
            }
          });
        }
      });
    }
  };

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title">
          <h2>Add User</h2>
        </div>
        <div className="profile-details__inputs">
          <div className="profile-details__input-content">
            <Input
              htmlFor={"username"}
              labelText={
                <>
                  Username <RequiredStar />
                </>
              }
              type={"text"}
              id="username"
              value={userData.username}
              name="username"
              onChange={handleChange}
              className={userData.username === "" ? error : ""}
            />
            <Input
              htmlFor={"email"}
              labelText={
                <>
                  Email <RequiredStar />
                </>
              }
              type={"email"}
              id="email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              className={
                userData.email === "" || !validateEmail(userData.email)
                  ? error
                  : ""
              }
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"firstName"}
              labelText={
                <>
                  First Name <RequiredStar />
                </>
              }
              type={"text"}
              id="firstName"
              value={userData.firstname}
              name="firstname"
              onChange={handleChange}
              className={userData.firstname === "" ? error : ""}
            />
            <Input
              htmlFor={"lastName"}
              labelText={
                <>
                  Last Name <RequiredStar />
                </>
              }
              type={"text"}
              id="lastName"
              value={userData.lastName}
              name="lastName"
              onChange={handleChange}
              className={userData.lastName === "" ? error : ""}
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"password"}
              labelText={
                <>
                  Password <RequiredStar />
                </>
              }
              type={"password"}
              id="password"
              value={userData.password}
              name="password"
              onChange={handleChange}
              className={
                userData.password === "" ||
                userData.password.length < 6 ||
                !/\W/.test(userData.password) ||
                !/\d/.test(userData.password) ||
                !/[A-Z]/.test(userData.password)
                  ? error
                  : ""
              }
            />
            <Input
              htmlFor={"confirmPassword"}
              labelText={
                <>
                  Confirm Password <RequiredStar />
                </>
              }
              type={"password"}
              id="confirmPassword"
              name="confirmPassword"
              value={userData.confirmPassword}
              onChange={handleChange}
              className={
                userData.confirmPassword === "" ||
                userData.password !== userData.confirmPassword
                  ? error
                  : ""
              }
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"phoneNum"}
              labelText={"Phone Number"}
              type={"tel"}
              id="phoneNum"
              value={userData.phoneNumber}
              name="phoneNumber"
              onChange={handleChange}
            />

            <Input
              htmlFor={"city"}
              labelText={"City"}
              type={"text"}
              id="city"
              value={userData.city}
              name="city"
              onChange={handleChange}
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"address"}
              labelText={"Address"}
              type={"text"}
              id="address"
              value={userData.address}
              name="address"
              onChange={handleChange}
            />
            <UserRole
              onChange={setUserData}
              className={userData.role === 0 ? error : ""}
              required={true}
            />
          </div>
        </div>
        <div className="profile-details__buttons">
          <button className="save-changes" onClick={addUserClick}>
            Add User
          </button>
          <button onClick={handleCancle} className="discard">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default AddUser;
