import { Card } from "antd";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const ClientChart = ({ data }) => {
  const formatLegendText = () => {
    return "Clients";
  };

  return (
    <Card title="Active Clients Based on Countries" bordered={false}>
      <ResponsiveContainer
        width="100%"
        height={window.innerWidth < 576 ? 300 : 400}
      >
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="countryName" />
          <YAxis />
          <Tooltip formatter={(value, name) => [`Clients: ${value}`]} />

          <Legend formatter={formatLegendText} />
          <Bar
            dataKey="count"
            stackId="a"
            fill="#82ca9d"
            barSize={window.innerWidth < 576 ? 20 : 50}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default ClientChart;
