import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./Components/Pages/Root";
import LoginPage from "./Components/Pages/LoginPage";
import ErrorPage from "./Components/Pages/ErrorPage";
import ForgotPassword from "./Components/Login/ForgotPassword";
import CreateAnAccount from "./Components/Login/CreateAnAccount";
import LoginLayout from "./Components/Pages/LoginLayout";
import ChangePassword from "./Components/Profile/ChangePassword";
import ResetPassword from "./Components/Login/ResetPassword";
import ProfileDetails from "./Components/Profile/ProfileDetails";
import UsersList from "./Components/Users/UsersList";
import AddUser from "./Components/Users/AddUser";
import EditUser from "./Components/Users/EditUser";
import ClientList from "./Components/ClientsList/ClientList";
import ClientDetails from "./Components/ClientsList/ClientDetails";
import PriceList from "./Components/PriceList/PriceList";
import EditPriceList from "./Components/PriceList/EditPriceList";
import Services from "./Components/TransportServices/Services";
import AddServices from "./Components/TransportServices/AddServices";
import EditService from "./Components/TransportServices/EditService";
import Contact from "./Components/Contact/Contact";
import ContactMessage from "./Components/Contact/ContactMessage";
import ClientCars from "./Components/ClientsList/ClientCars";
import { loader as AuthLoader } from "./Components/Pages/Root";
import ForgotPasswordConfirmation from "./Components/Login/ForgotPasswordConfirmation";
import ArvalApi from "./Components/AppServices/ArvalApi";
import { AuthContextProvider } from "./Context/AuthContext";
import Dashboard from "./Components/Dashboard/Dashboard";
import ConfirmedCars from "./Components/CarsList/ConfirmedCars";
import RecievedCars from "./Components/CarsList/RecievedCars";
import CarDetails from "./Components/CarsList/CarDetails/CarDetails";
import InvoiceFile from "./Components/Modals/InvoiceFile";
import Invoices from "./Components/Invoices/Invoices";
import { webControls } from "./endpoint";
import { createGlobalStyle } from "styled-components";

import Carmax from "./assets/images/carmax-favicon.png";
import CarmaxKS from "./assets/images/carmaxks-favicon.png";
import UnderConsiderationCars from "./Components/CarsList/UnderConsiderationCars";
import ProformaInvoices from "./Components/Invoices/ProformaInvoices";
import DefInvoiceServicesList from "./Components/DefInvoiceServices/DefInvoiceServicesList";

const router = createBrowserRouter([
  {
    path: "/dashboard",
    element: <RootLayout />,
    loader: AuthLoader,
    errorElement: <ErrorPage />,
    children: [
      { path: "change-password", element: <ChangePassword /> },
      { path: "profile", element: <ProfileDetails /> },
      { path: "clients-list", element: <ClientList /> },
      {
        path: "clients-list/client-details/:clientDetailsId",
        element: <ClientDetails />,
      },
      {
        path: "home",
        element: <Dashboard />,
      },
      { path: "clients-list/client-cars/:clientId", element: <ClientCars /> },
      { path: "price-list", element: <PriceList /> },
      { path: "price-list/edit", element: <EditPriceList /> },
      { path: "services", element: <Services /> },
      { path: "services/edit-service/:serviceId", element: <EditService /> },
      { path: "services/add-services", element: <AddServices /> },
      { path: "contact", element: <Contact /> },
      {
        path: "contact/contact-message/:contactClientId",
        element: <ContactMessage />,
      },
      { path: "users-list", element: <UsersList /> },
      { path: "users-list/edit-user/:userId", element: <EditUser /> },
      { path: "add-user", element: <AddUser /> },
      { path: "external-api/:name", element: <ArvalApi /> },
      { path: "underconsideration-cars", element: <UnderConsiderationCars /> },
      { path: "confirmed-cars", element: <ConfirmedCars /> },
      { path: "received-cars", element: <RecievedCars /> },
      { path: "car/:id", element: <CarDetails /> },
      { path: "proforma-invoices", element: <ProformaInvoices /> },
      { path: "invoices", element: <Invoices /> },
      { path: "invoice-services", element: <DefInvoiceServicesList /> },
    ],
  },
  {
    path: "/",
    element: <LoginLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      { path: "forgot-password", element: <ForgotPassword /> },
      {
        path: "forgot-password/forgot-password-confirmation",
        element: <ForgotPasswordConfirmation />,
      },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "create-an-account", element: <CreateAnAccount /> },
      {
        path: "view-invoice/:id",
        element: <InvoiceFile />,
      },
    ],
  },
]);

function App() {
  document.title = webControls.isCarmax ? "CARMAX" : "CARMAX-KS";

  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = webControls.isCarmax ? Carmax : CarmaxKS;

  const GlobalStyles = createGlobalStyle`
:root {
  --primaryColor: ${webControls.isCarmax ? "#336699" : "#014175"};
  --headerBackground: ${webControls.isCarmax ? "#fafafa" : "#000"};
  --socialMediaBackground : ${
    webControls.isCarmax
      ? "linear-gradient(-45deg, #2f5e8e 40%, #336699 40%)"
      : "#fff"
  };
  --navbarLinkColor: ${webControls.isCarmax ? "#222222" : "#fff"};
  --navbarUnderlineLinkColor: ${webControls.isCarmax ? "#f4c23d" : "#014175"};
  --blackAndWhite: ${webControls.isCarmax ? "#fff" : "#000"};
  --whyChooseUsBgColor: ${webControls.isCarmax ? "#f5fafe" : "#fff"};
  --blackToWhite:  ${webControls.isCarmax ? "#12100b" : "#fff"};
  --primaryToBloack: ${webControls.isCarmax ? "#336699" : "#000"};

  --registerForFreeHoverBtn: ${webControls.isCarmax ? "#24486c" : "#a50000"};

  --grayishToBloack: ${webControls.isCarmax ? "#8997a5" : "#000"};


  --footerBg: ${webControls.isCarmax ? "#26313c" : "#fff"};

  --subFooterBg: ${webControls.isCarmax ? "#1e2832" : "#fff"};

  --primaryToBlack:  ${webControls.isCarmax ? "#336699" : "#000"};

  --registerNowTitleColor:  ${webControls.isCarmax ? "#218aac" : "#014175"};
  --registerNowButtonHoverColor:  ${
    webControls.isCarmax ? "#1b738f" : "#a50000"
  };

  --secondaryToBlack:  ${webControls.isCarmax ? "#f4c23d" : "#000"};
  --secondaryToGray:  ${webControls.isCarmax ? "#f4c23d" : "#f0f0f0"};

  --auctionBg:  ${webControls.isCarmax ? "#f5fafe" : "#ebf0f5"};

  --whiteToPrimary:  ${webControls.isCarmax ? "#fff" : "#014175"};

}`;
  return (
    <>
      <GlobalStyles />
      <AuthContextProvider>
        <RouterProvider router={router} />
      </AuthContextProvider>
    </>
  );
}

export default App;
