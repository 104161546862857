import React from "react";
import { Select } from "antd";
import { UserStatusEnum } from "../../enum";

function StatusSearchSelect({ setValue, value }) {
  const options = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Active",
      value: UserStatusEnum.Active,
    },
    {
      label: "Passive",
      value: UserStatusEnum.Passive,
    },
  ];

  return (
    <>
      <div className="country-content">
        <label className="country-label" htmlFor="userStatus">
          Status
        </label>
        <Select
          id="userStatus"
          options={options}
          defaultValue="Choose Status"
          value={value}
          onChange={(e) => setValue(e)}
        />
      </div>
    </>
  );
}

export default StatusSearchSelect;
