import { Checkbox } from "antd";
import React, { useState } from "react";

import Input from "../../utilities/Input/Input";

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaEnvelope,
  FaGithubSquare,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { webControls } from "../../endpoint";

function CreateAnAccount() {
  const navigate = useNavigate();

  const [dataForm, setDataForm] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [usernameIsValid, setUsernameIsValid] = useState({
    valid: true,
  });

  const [emailIsValid, setEmailIsValid] = useState({
    valid: true,
  });

  const [passwordIsValid, setPasswordIsValid] = useState({
    valid: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "username") {
      setUsernameIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: "Username cannot be empty",
        error: "error-input",
      }));
    }
    if (name === "email") {
      setEmailIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: "Email field cannot be empty",
        error: "error-input",
      }));
    }
    if (name === "password") {
      setPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: "Password field cannot be empty!",
        error: "error-input",
      }));
    }
    if (name === "password") {
      setPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 8),
        message: "Password must be at least 8 characters",
        error: "error-input",
      }));
    }
  };

  const handleSubmit = () => {
    if (dataForm.username.trim().length === 0) {
      setUsernameIsValid((prev) => {
        return {
          valid: false,
          message: "Username cannot be empty",
          error: "error-input",
        };
      });
    } else {
      setUsernameIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }
    if (dataForm.email.trim().length === 0) {
      setEmailIsValid((prev) => {
        return {
          valid: false,
          message: "Email field cannot be empty",
          error: "error-input",
        };
      });
    } else {
      setEmailIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.password.trim().length === 0) {
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Password field cannot be empty!",
          error: "error-input",
        };
      });
    } else if (dataForm.password.trim().length < 8) {
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Password must be at least 8 characters",
          error: "error-input",
        };
      });
    } else {
      setPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }
    if (
      dataForm.username.trim().length !== 0 &&
      dataForm.email.trim().length !== 0 &&
      dataForm.password.trim().length > 7
    ) {
      navigate("..");
    }
  };

  return (
    <>
      <div className="login-bg">
        <div className="login">
          <span className="login__company">
            {webControls.isCarmax ? "CARMAX" : "CARMAX-KS"}
          </span>
          <h3 className="login__title">Adventure starts here!</h3>
          <p>Make your app management easy and fun!</p>
          <div className="login__inputs">
            <div className="input-error">
              <Input
                htmlFor={"username"}
                placeholder={"johndoe"}
                labelText={"Username"}
                type={"text"}
                id="username"
                name="username"
                onChange={handleChange}
                value={dataForm.username}
                className={!usernameIsValid.valid && usernameIsValid.error}
              />
              {!usernameIsValid.valid && (
                <p className="error-message">{usernameIsValid.message}</p>
              )}
            </div>
            <div className="input-error">
              <Input
                htmlFor={"email"}
                placeholder={"john@example.com"}
                labelText={"Email"}
                type={"email"}
                id="email"
                name="email"
                onChange={handleChange}
                value={dataForm.email}
                className={!emailIsValid.valid && emailIsValid.error}
              />
              {!emailIsValid.valid && (
                <p className="error-message">{emailIsValid.message}</p>
              )}
            </div>
            <div className="input-error">
              <Input
                htmlFor={"password"}
                placeholder={"••••••••"}
                labelText={"Password"}
                type={"password"}
                id="password"
                name="password"
                onChange={handleChange}
                value={dataForm.password}
                className={!passwordIsValid.valid && passwordIsValid.error}
              />
              {!passwordIsValid.valid && (
                <p className="error-message">{passwordIsValid.message}</p>
              )}
            </div>
          </div>
          <Checkbox className="checkbox">
            I agree to privacy policy & terms
          </Checkbox>
          <button onClick={handleSubmit} className="login__button">
            Sign up
          </button>
          <span className="login__new-on-platform">
            Already have an account?{" "}
            <Link className="create-account__link" to="..">
              Sign in instead
            </Link>
          </span>
          <span className="login__or">or</span>
          <div className="login__social-links">
            <FaFacebookSquare className="fb-link" />
            <FaTwitterSquare className="twitter-link" />
            <FaEnvelope className="gmail-link" />
            <FaGithubSquare className="github-link" />
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateAnAccount;
