import { toast } from "react-hot-toast";
import { ResetUserPassword, webControls } from "../../endpoint";
import Input from "../../utilities/Input/Input";

import "./ForgotPassword.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const navigate = useNavigate();

  const [dataForm, setDataForm] = useState({
    token: "",
    password: "",
    confirmNewPassword: "",
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const t = urlParams.get("token");
    const tokenWithPlus = t.replace(/ /g, "+");

    setDataForm((prevData) => ({
      ...prevData,
      token: tokenWithPlus,
    }));
  }, []);

  const [passwordIsValid, setPasswordIsValid] = useState({
    valid: true,
  });

  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState({
    valid: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "password") {
      setPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: "Please enter a valid password.",
      }));
    }

    if (name === "password") {
      setPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 8),
        message: "Password must be at least 8 characters",
      }));
    }

    if (name === "confirmNewPassword") {
      setConfirmPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: "Please enter a valid confirm password",
      }));

      setConfirmPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: dataForm.password === value,
        message: "Password do not match",
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (dataForm.password.trim().length === 0) {
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Please enter a valid password",
        };
      });
    } else if (dataForm.password.trim().length < 8) {
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Password must be at least 8 characters",
        };
      });
    } else {
      setPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.confirmNewPassword.trim().length === 0) {
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Please enter a valid confirm password",
        };
      });
    } else if (dataForm.confirmNewPassword !== dataForm.password) {
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Password do not match",
        };
      });
    } else {
      ResetUserPassword(dataForm).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            id: "resetPassword",
          });
          navigate("/");
        } else {
          const errMessage = res.data.message.split(";");
          errMessage.map((message, i) => {
            toast.error(message, {
              id: "resetError" + i,
            });
          });
        }
      });
      setConfirmPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }
  };

  return (
    <>
      <div className="login-bg">
        <form className="login" onSubmit={handleSubmit}>
          <span className="login__company">
            {webControls.isCarmax ? "CARMAX" : "CARMAX-KS"}
          </span>
          <h3 className="login__title">Reset Password</h3>
          <div className="input-error input-error--width">
            <Input
              htmlFor={"password"}
              labelText={"Password"}
              type={"password"}
              id="password"
              name="password"
              value={dataForm.password}
              onChange={handleChange}
            />
            {!passwordIsValid.valid && (
              <p className="error-message">{passwordIsValid.message}</p>
            )}
          </div>
          <div className="input-error input-error--width">
            <Input
              htmlFor={"confirmNewPassword"}
              labelText={"Confirm Password"}
              type={"password"}
              id="confirmNewPassword"
              name="confirmNewPassword"
              value={dataForm.confirmNewPassword}
              onChange={handleChange}
            />
            {!confirmPasswordIsValid.valid && (
              <p className="error-message">{confirmPasswordIsValid.message}</p>
            )}
          </div>
          <button className="login__button">Reset</button>
        </form>
      </div>
    </>
  );
}

export default ResetPassword;
