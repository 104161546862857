import React, { useEffect } from "react";
import { Select } from "antd";
import { TypeOfActivity } from "../../enum";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function TypeOfActivitySelect({
  defaultValue,
  value,
  setSelectedActivity,
  className,
  disabled,
  required = false,
}) {
  useEffect(() => {
    setSelectedActivity(defaultValue);
  }, [setSelectedActivity, defaultValue]);

  const options = [
    {
      label: "Company is a car trader",
      value: TypeOfActivity.IsCarTrader,
    },
    {
      label: "Company is not a car trader",
      value: TypeOfActivity.IsNotCarTrader,
    },
  ];

  return (
    <>
      <div className="country-content">
        <label className="country-label" htmlFor="roles">
          Type Of Activity {required && <RequiredStar />}
        </label>
        <Select
          id="roles"
          options={options}
          value={value}
          className={className}
          defaultValue={defaultValue}
          onChange={(selectedOption) => setSelectedActivity(selectedOption)}
          disabled={disabled}
        />
      </div>
    </>
  );
}

export default TypeOfActivitySelect;
