import React, { useContext, useEffect, useState } from "react";
import Input from "../../utilities/Input/Input";
import { useNavigate, useParams } from "react-router-dom";
import { GetUserDetails, UpdateUserDetails } from "../../endpoint";
import UserRole from "./UserRole";
import { toast } from "react-hot-toast";
import StatusSelect from "./StatusSelect";
import { AuthContext } from "../../Context/AuthContext";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function EditUser() {
  const { userId } = useParams();

  const ctx = useContext(AuthContext);

  const [error, setError] = useState("");

  const [userDetails, setUserDetails] = useState({
    userName: "",
    firstName: " ",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    address: "",
    role: 0,
    status: 0,
  });

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((userDetails) => {
      return {
        ...userDetails,
        [name]: value,
      };
    });
  };

  const navigate = useNavigate();

  const handleSaveChanges = () => {
    if (
      userDetails.userName === "" ||
      userDetails.firstName === "" ||
      userDetails.lastName === "" ||
      userDetails.email === "" ||
      userDetails.role === 0 ||
      userDetails.status === 0
    ) {
      toast.error("Inputs cannot be empty!", {
        duration: 2000,
        id: "inputsCannotBeEmpty",
      });
      setError("input-empty-red ");
    } else if (!validateEmail(userDetails.email)) {
      toast.error("Please enter a valid email address!", {
        duration: 2000,
        id: "eneterAvalidEmailAddress",
      });
      setError("input-empty-red");
    } else {
      setError("");
      UpdateUserDetails(userDetails)
        .then((res) => {
          if (res.data.success) {
            if (userDetails.userId === ctx.data.userId) {
              ctx.setData((prevData) => ({
                ...prevData,
                firstName: userDetails.firstName,
                lastName: userDetails.lastName,
                role: userDetails.role,
              }));
            }
          }
          toast.success(res.data.message, {
            duration: 2000,
            id: "userEditedSuccessfuly",
          });
        })
        .finally(() => {
          navigate("/dashboard/users-list");
        });
    }
  };

  const handleDiscard = () => {
    navigate("/dashboard/users-list");
  };

  useEffect(() => {
    GetUserDetails(userId).then((res) => {
      setUserDetails(res.data);
    });
  }, [userId]);

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title">
          <h2>Edit User</h2>
        </div>
        <div className="profile-details__inputs">
          <div className="profile-details__input-content">
            <Input
              htmlFor={"username"}
              labelText={
                <>
                  Username <RequiredStar />
                </>
              }
              type={"text"}
              id="username"
              name="userName"
              value={userDetails.userName}
              onChange={handleChange}
              className={userDetails.userName === "" ? error : ""}
            />
            <Input
              htmlFor={"email"}
              labelText={
                <>
                  Email <RequiredStar />
                </>
              }
              type={"email"}
              id="email"
              value={userDetails.email}
              name="email"
              onChange={handleChange}
              className={
                userDetails.email === "" || !validateEmail(userDetails.email)
                  ? error
                  : ""
              }
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"firstName"}
              labelText={
                <>
                  First Name <RequiredStar />
                </>
              }
              type={"text"}
              id="firstName"
              value={userDetails.firstName}
              name="firstName"
              onChange={handleChange}
              className={userDetails.firstName === "" ? error : ""}
            />
            <Input
              htmlFor={"lastName"}
              labelText={
                <>
                  Last Name <RequiredStar />
                </>
              }
              type={"text"}
              id="lastName"
              value={userDetails.lastName}
              name="lastName"
              onChange={handleChange}
              className={userDetails.lastName === "" ? error : ""}
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"city"}
              labelText={"City"}
              type={"text"}
              id="city"
              value={userDetails.city}
              name="city"
              onChange={handleChange}
            />
            <Input
              htmlFor={"address"}
              labelText={"Address"}
              type={"text"}
              id="address"
              value={userDetails.address}
              name="address"
              onChange={handleChange}
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"phoneNum"}
              labelText={"Phone Number"}
              type={"tel"}
              id="phoneNum"
              value={userDetails.phoneNumber}
              name="phoneNumber"
              onChange={handleChange}
            />
            <UserRole
              onChange={setUserDetails}
              value={userDetails.role}
              className={userDetails.role === 0 ? error : ""}
              required={true}
            />
          </div>
          <div
            className="profile-details__input-content"
            style={{ width: "calc(50% - 1.5rem)" }}
          >
            <StatusSelect
              onChange={setUserDetails}
              value={userDetails.status}
              className={userDetails.status === 0 ? error : ""}
            />
          </div>
        </div>
        <div className="profile-details__buttons">
          <button className="save-changes" onClick={handleSaveChanges}>
            Save changes
          </button>
          <button onClick={handleDiscard} className="discard">
            Discard
          </button>
        </div>
      </div>
    </>
  );
}

export default EditUser;
