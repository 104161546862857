import { Card, Col, Row, Statistic } from "antd";
import CountUp from "react-countup";

function DashboardHeader({ data }) {
  const formatter = (value) => <CountUp end={value} separator="," />;

  return (
    <>
      <Row gutter={[24, 8]}>
        <Col lg={6} md={12} sm={24} xs={24}>
          <Card bordered={false}>
            <Statistic
              title="Active Clients"
              value={data.activeClients}
              formatter={formatter}
              valueStyle={{
                color: "#3f8600",
                fontSize: "30px",
              }}
            />
          </Card>
        </Col>
        <Col lg={6} md={12} sm={24} xs={24}>
          <Card bordered={false}>
            <Statistic
              title="Under Consideration Cars"
              value={data.underConsiderationCars}
              formatter={formatter}
              valueStyle={{
                color: "#cf1322",
                fontSize: "30px",
              }}
            />
          </Card>
        </Col>
        <Col lg={6} md={12} sm={24} xs={24}>
          <Card bordered={false}>
            <Statistic
              title="Confirmed Cars"
              value={data.confirmedCars}
              formatter={formatter}
              align="middle"
              valueStyle={{
                color: "rgb(255, 159, 67)",
                fontSize: "30px",
              }}
            />
          </Card>
        </Col>
        <Col lg={6} md={12} sm={24} xs={24}>
          <Card bordered={false}>
            <Statistic
              title="Received Cars"
              value={data.receivedCars}
              formatter={formatter}
              align="middle"
              valueStyle={{
                color: "rgb(51 102 153)",
                fontSize: "30px",
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DashboardHeader;
