import { Modal, Tooltip } from "antd";
import React, { useState, useRef } from "react";

import { BsFiletypePdf } from "react-icons/bs";
import { FaRegFileImage } from "react-icons/fa";

import "./DocumentPreview.css";

function DocumentPreview({ documents, name }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const downloadLinkRef = useRef(null);
  const [document, setDocument] = useState({});


  const previewDocument = (document) => {
    setDocument(document);

    setIsModalOpen(true);
  };

  const handleOk = () => {
    downloadLinkRef.current.click();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="documents-container-width">
      <div className="document-container">
        {documents?.map((document) => {
          return (
            <span key={document.id}
              className={
                document.documentContentType === "application/pdf"
                  ? "pdf-icon"
                  : "image-icon"
              }
            >
              {document.documentContentType === "application/pdf" ? (
                <Tooltip placement="top" title="View" color="#f40f02">
                  <BsFiletypePdf
                    id={document.id}
                    onClick={() => {
                      previewDocument(document);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip placement="top" title="View" color="#336699">
                  <FaRegFileImage
                    id={document.id}
                    onClick={() => {
                      previewDocument(document);
                    }}
                  />
                </Tooltip>
              )}
            </span>
          );
        })}

        <Modal
          title={name}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered={true}
          okText="Download"
        >
          {document.documentContentType === "application/pdf" ? (
            <iframe
              src={`data:${document.documentContentType};base64,${document.documentContentBase64}`}
              frameBorder="0"
              title="iframe"
              width="70vw"
              height="70vh"
              className="iframe-pdf"
            ></iframe>
          ) : (
            <img
              src={`data:${document.documentContentType};base64,${document.documentContentBase64}`}
              alt=""
              style={{ width: "100%" }}
            />
          )}
        </Modal>
        <a
          ref={downloadLinkRef}
          href={`data:${document.documentContentType};base64,${document.documentContentBase64}`}
          style={{ display: "none" }}
          download
        >
          Download Link
        </a>
      </div>
    </div>
  );
}

export default DocumentPreview;
