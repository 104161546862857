import React, { useEffect, useState } from "react";
import Input from "../../utilities/Input/Input";

import { useNavigate } from "react-router-dom";
import { EditPriceListAxios, GetPriceList } from "../../endpoint";
import { toast } from "react-hot-toast";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function EditPriceList() {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/dashboard/price-list");
  };

  const [priceDetails, setPriceDetails] = useState({
    bankFee: "",
    auctionServiceFee: "",
    // transport: "",
    auctionServicefeeOver10k: "",
  });

  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setPriceDetails((prevPriceList) => ({
      ...prevPriceList,
      [name]: value,
    }));
  };

  useEffect(() => {
    GetPriceList().then((res) =>
      setPriceDetails({
        bankFee: res.data.bankFee,
        auctionServiceFee: res.data.auctionServiceFee,
        // transport: res.data.transport,
        auctionServicefeeOver10k: res.data.auctionServicefeeOver10k,
      })
    );
  }, []);

  const handleClick = () => {
    if (
      priceDetails.bankFee === "" ||
      priceDetails.auctionServiceFee === "" ||
      // priceDetails.transport === "" ||
      priceDetails.auctionServicefeeOver10k == ""
    ) {
      setError("input-red");
      toast.error("Inputs cannot be empty!", {
        duration: 2000,
        id: "priceListInputs",
      });
    } else {
      EditPriceListAxios(priceDetails).then((res) => {
        toast.success(res.data.message, {
          duration: 2000,
          id: "priceListUpdated",
        });
        navigate("/dashboard/price-list");
      });
    }
  };

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title">
          <h2>Edit Price List</h2>
        </div>
        <div className="profile-details__inputs">
          <div className="profile-details__input-content">
            <Input
              htmlFor={"bankFee"}
              labelText={
                <>
                  Bank Fee <RequiredStar />
                </>
              }
              type={"number"}
              id="bankFee"
              value={priceDetails.bankFee}
              name="bankFee"
              onChange={handleChange}
              className={priceDetails.bankFee === "" ? error : ""}
            />
            <Input
              htmlFor={"auctionServiceFee"}
              labelText={
                <>
                  Auction Service Fee <RequiredStar />
                </>
              }
              type={"number"}
              id="auctionServiceFee"
              name="auctionServiceFee"
              value={priceDetails.auctionServiceFee}
              onChange={handleChange}
              className={priceDetails.auctionServiceFee === "" ? error : ""}
            />
            {/* <Input
              htmlFor={"exportDecleration"}
              labelText={
                <>
                  Export Decleration
                  <RequiredStar />
                </>
              }
              type={"number"}
              id="exportDecleration"
              name="exportDeclarationFee"
              value={priceDetails.exportDeclarationFee}
              onChange={handleChange}
              className={priceDetails.exportDeclarationFee === "" ? error : ""}
            /> */}
            {/* <Input
              htmlFor={"transport"}
              labelText={
                <>
                  Transport <RequiredStar />
                </>
              }
              type={"number"}
              id="transport"
              name="transport"
              value={priceDetails.transport}
              onChange={handleChange}
              className={priceDetails.transport === "" ? error : ""}
            /> */}
            <Input
              htmlFor={"auctionServicefeeOver10k"}
              labelText={
                <>
                  Auction Service Fee Over 20k
                  <RequiredStar />
                </>
              }
              type={"number"}
              id="auctionServicefeeOver10k"
              name="auctionServicefeeOver10k"
              value={priceDetails.auctionServicefeeOver10k}
              onChange={handleChange}
              className={
                priceDetails.auctionServicefeeOver10k === "" ? error : ""
              }
            />
          </div>
        </div>
        <div className="profile-details__buttons">
          <button onClick={handleClick} className="save-changes">
            Save changes
          </button>
          <button onClick={handleCancel} className="discard">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default EditPriceList;
