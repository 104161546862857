import React, { useEffect, useState } from "react";
import Input from "../../utilities/Input/Input";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import toast from "react-hot-toast";

const InvoiceLineInputsWithCode = ({
  invoiceLine,
  invoiceLines,
  setInvoiceLines,
  index,
  descriptionError,
  createNewInvoice = false,
}) => {
  const handleChange = (e) => {
    const updatedLines = [...invoiceLines];
    const index = updatedLines.findIndex(
      (line) => line.description === invoiceLine.description
    );

    if (index !== -1) {
      let priceWithVat;
      if ([e.target.name] == "priceWithoutVAT") {
        priceWithVat =
          (parseInt(e.target.value) || 0) +
          parseInt(updatedLines[index].vatRate || 0);
      } else if ([e.target.name] == "vatRate") {
        priceWithVat =
          parseInt(updatedLines[index].priceWithoutVAT || 0) +
          (parseInt(e.target.value) || 0);
      } else {
        priceWithVat =
          (parseInt(updatedLines[index].priceWithoutVAT) || 0) +
          (parseInt(updatedLines[index].vatRate) || 0);
      }
      updatedLines[index] = {
        ...updatedLines[index],
        [e.target.name]: e.target.value || null,
        priceWithVAT: priceWithVat,
      };
      setInvoiceLines(updatedLines);
    }
  };

  const handleDeleteInvoiceLine = (index) => {
    if (invoiceLines?.length > 1) {
      const updatedInvoiceLines = [...invoiceLines];
      updatedInvoiceLines.splice(index, 1);
      setInvoiceLines(updatedInvoiceLines);
    } else {
      toast.error("Can not delete all invoice lines!", {
        duration: 2000,
        id: "invoiceConfirmFailed",
      });
    }
  };

  return (
    <>
      <span className="car-details-info-about-car__content-line "></span>
      <div
        style={{
          alignItems: "center",
          gap: "0.5rem",
          margin: "0.5rem 0",
          display: "flex",
        }}
        className="input-container"
      >
        <div
          style={{ width: "75px", fontWeight: "600" }}
          className="input-width"
        >
          <Input
            placeholder={""}
            labelText={""}
            type={"text"}
            id="code"
            value={invoiceLine?.code}
            onChange={handleChange}
            name="code"
          />
        </div>
        <div
          style={{ width: "365px", fontWeight: "600" }}
          className="input-width"
        >
          <textarea
            style={{ minHeight: "44px" }}
            placeholder={""}
            labelText={""}
            type={"text"}
            id="description"
            value={invoiceLine?.description}
            onChange={handleChange}
            name="description"
            className={`input ${
              invoiceLine.description === "" ? descriptionError : ""
            }`}
            rows={1}
          />
        </div>
        <div style={{ width: "175px" }} className="input-width">
          <Input
            placeholder={""}
            labelText={""}
            type={"number"}
            id="priceWithoutVAT"
            value={invoiceLine?.priceWithoutVAT}
            onChange={handleChange}
            name="priceWithoutVAT"
          />
        </div>
        <div style={{ width: "175px" }} className="input-width">
          <Input
            placeholder={""}
            labelText={""}
            type={"number"}
            id="vatRate"
            value={invoiceLine?.vatRate}
            onChange={handleChange}
            name="vatRate"
          />
        </div>
        <div style={{ width: "175px" }} className="input-width">
          <Input
            type={"text"}
            id="priceWithVAT"
            value={
              (parseInt(invoiceLine?.priceWithoutVAT) || 0) +
              (parseInt(invoiceLine?.vatRate) || 0)
            }
            disabled={true}
            onChange={() => {}}
            name="priceWithVAT"
          />
        </div>
        <Popconfirm
          className="popDeletePlanner"
          title={"Delete Invoice Line"}
          description={"Are you sure you want to delete this invoice line?"}
          icon={
            <QuestionCircleOutlined
              style={{
                color: "#d00",
              }}
            />
          }
          okText={"Yes"}
          cancelText={"No"}
          placement={"leftBottom"}
          onConfirm={() => handleDeleteInvoiceLine(index)}
        >
          <DeleteOutlined
            style={{
              paddingBottom: "11px",
              color: "#d00",
              cursor: "pointer",
              marginBottom: "auto",
              marginTop: "13px",
            }}
          />
        </Popconfirm>
      </div>
    </>
  );
};

export default InvoiceLineInputsWithCode;
