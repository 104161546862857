import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import {
  DownloadInvoice,
  GetInvoiceByCarId,
  webControls,
} from "../../endpoint";
import "./InvoiceDetailsModal.css";
import { IoMdDownload } from "react-icons/io";
import companyLogo from "../../assets/images/carmax-logo-ph.png";
import CarmaxKsLogo from "../../assets/images/carmax-logo-red-and-blackversion.png";
import { Link, useNavigate } from "react-router-dom";
import Preloader from "../../utilities/Preloader/Preloader";

const columns = [
  {
    title: "Charges",
    dataIndex: "charges1",
    key: "charges1",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "",
    dataIndex: "charges2",
    key: "charges2",
    render: (text) => <a>{text}</a>,
  },
];

const InvoiceDetailsModal = ({
  showInvoiceModal,
  carId,
  setShowInvoiceModal,
}) => {
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [isReady, setIsReady] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (showInvoiceModal) {
      GetInvoiceByCarId(carId).then((res) => {
        setInvoiceDetails(res.data);
        setIsReady(true);
      });
    }
  }, [showInvoiceModal]);

  const data = [
    {
      charges1: "Car Price",
      charges2: <>{invoiceDetails?.carPrice}€</>,
    },
    {
      charges1: "Bank Fee",
      charges2: <>{invoiceDetails?.bankFee}€</>,
    },
    {
      charges1: "Transport",
      charges2: <>{invoiceDetails?.transport}€</>,
    },
    {
      charges1: "Auction Service Fee",
      charges2: <>{invoiceDetails?.auctionServiceFee}€</>,
    },
    {
      charges1: "Total Amount",
      charges2: <>{invoiceDetails?.totalAmount}€</>,
    },
  ];

  return (
    <Modal
      className="invoice-modal"
      title={
        <div>
          <div>
            INVOICE <span>#{invoiceDetails?.invoiceNo}</span>
          </div>
          <img src={webControls.isCarmax ? companyLogo : CarmaxKsLogo} alt="" />
        </div>
      }
      centered
      open={showInvoiceModal}
      width={600}
      closable={false}
      onCancel={() => setShowInvoiceModal(false)}
      footer={[
        <Button
          type="primary"
          ghost
          onClick={() => setShowInvoiceModal(false)}
          className="invoice-downloadbtn"
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          className="invoice-downloadbtn"
          onClick={() =>
            DownloadInvoice(invoiceDetails.id, invoiceDetails.carId)
          }
        >
          {/* <Link
            style={{ textDecoration: "none" }}
            to={`/view-invoice/${invoiceDetails?.id}`}
            target="_blank"
          > */}
          <IoMdDownload /> Download
          {/* </Link> */}
        </Button>,
      ]}
    >
      <Preloader isReady={isReady}>
        <div className="invoice-clientinfo">
          <div className="client-contactinfo">
            <div>
              <h3>Client Contact Info </h3>
              <p>Email: {invoiceDetails?.clientEmail}</p>
              <p>Phone Number: {invoiceDetails?.clientMobileNo}</p>
            </div>
            <div>
              <h3>Bill to </h3>
              <p>Company Name: {invoiceDetails?.companyName}</p>
              <p>Address: {invoiceDetails?.clientAddress}</p>
              <p>City: {invoiceDetails?.clientCity}</p>
              <p>Zip Code: {invoiceDetails?.clientZipCode}</p>
            </div>
          </div>
          <div>
            <h3>Client Info </h3>
            <p>
              Name: {invoiceDetails?.clientName}{" "}
              {invoiceDetails?.clientLastName}{" "}
            </p>
            <p>
              Registration Number: {invoiceDetails?.clientRegistrationNumber}
            </p>
            <p>
              VAT Registration Number:{" "}
              {invoiceDetails?.clientVATRegistrationNumber}
            </p>
          </div>
        </div>
        <div className="invoice-info">
          <Table
            className="invoice-table"
            columns={columns}
            dataSource={data}
            pagination={{
              position: ["none", "none"],
            }}
          />
        </div>
      </Preloader>
    </Modal>
  );
};

export default InvoiceDetailsModal;
