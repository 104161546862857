import React from "react";
import { Select } from "antd";
import { UserStatusEnum } from "../../enum";

function StatusSelect({ onChange, className, value, disabled }) {
  const options = [
    {
      label: "Active",
      value: UserStatusEnum.Active,
    },
    {
      label: "Passive",
      value: UserStatusEnum.Passive,
    },
  ];

  return (
    <>
      <div className="country-content">
        <label className="country-label" htmlFor="status">
          Status
        </label>
        <Select
          id="status"
          disabled={disabled}
          options={options}
          defaultValue="Choose Status"
          value={value}
          onChange={(e) => {
            onChange((prev) => {
              return {
                ...prev,
                status: e,
              };
            });
          }}
          className={className}
        />
      </div>
    </>
  );
}

export default StatusSelect;
