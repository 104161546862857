import React, { useEffect, useReducer, useState } from "react";
import "./ArvalApi.css";
import { Button } from "antd";
import { useParams } from "react-router-dom";
import {
  GetAppServiceDetails,
  GetAppServices,
  UpdateAppService,
} from "../../endpoint";
import ApiInput from "../../utilities/Input/ApiInput";
import { toast } from "react-hot-toast";
import { AppSettingType } from "../../enum";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

const initialArray = [{}];

function ApiDispatcher(state, action) {
  if (action.type === "INITIAL_STATE") {
    return action.data;
  }

  if (action.type === "EDIT_STATE") {
    const inputEvent = action.target.target;
    const findEditedAuthorObj = state.find((el) => el.id === action.id);
    if (findEditedAuthorObj) {
      const indexOfEditedAuthorObj = state.indexOf(findEditedAuthorObj);
      const editedAuthorObj = state[indexOfEditedAuthorObj];
      editedAuthorObj.value = inputEvent.value;
      return state;
    }
  }

  return initialArray;
}

function ArvalApi() {
  const { name } = useParams();

  const [apiData, apiDataDispatcher] = useReducer(ApiDispatcher, initialArray);

  const [appServices, setAppServices] = useState([]);
  const [apiName, setApiName] = useState("");
  const [inputError, setInputError] = useState("");

  useEffect(() => {
    GetAppServices().then((res) => {
      setAppServices(res.data);
    });
    GetAppServiceDetails(name).then((res) => {
      apiDataDispatcher({ type: "INITIAL_STATE", data: res.data });
    });
  }, [name]);

  useEffect(() => {
    appServices.map(() => {
      const element = appServices.find((el) => el?.name === +name);
      const name1 = element?.name;
      setApiName(name1);
      return null;
    });
  }, [appServices, name]);

  const handleSave = () => {
    let hasEmptyValue = false;

    apiData.forEach((data, index) => {
      if (data.value === "") {
        hasEmptyValue = true;
      }
    });

    if (hasEmptyValue) {
      toast.error("Fields can not be empty!", {
        duration: 2000,
        id: "textareaEmpty",
      });
      setInputError("error-input");
    } else {
      UpdateAppService(apiData, name).then((res) => {
        toast.success(res.data.message, {
          duration: 2000,
          id: "appSuccessfullyUpdated",
        });
        setInputError("");
      });
    }
  };

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title">
          <h2>{apiName}</h2>
        </div>
        <div className="arval-content">
          {apiData?.map((api) => {
            return (
              <>
                <div key={api.id} className="arval-content__item">
                  <span>
                    {api.key}{" "}
                    <span style={{ color: "#d00" }}>
                      <RequiredStar />
                    </span>{" "}
                  </span>
                  <ApiInput
                    id={api.id}
                    data={apiData}
                    onChange={apiDataDispatcher}
                    name={api.key}
                    type={api.type}
                    className={inputError}
                  />
                </div>
              </>
            );
          })}
          <Button
            style={{ marginLeft: "auto" }}
            type="primary"
            onClick={handleSave}
            className="arval-btn"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

export default ArvalApi;
