import React from "react";
import { UserOutlined, LockOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import { Link } from "react-router-dom";
const items = [
  {
    key: "1",
    label: (
      <Link to="/dashboard/profile" className="profile-link">
        <UserOutlined />
        Profile
      </Link>
    ),
  },
  {
    key: "2",
    label: (
      <Link to="/dashboard/change-password" className="profile-link">
        <LockOutlined />
        Change password
      </Link>
    ),
  },
  {
    key: "3",
    label: (
      <Link
        to="/"
        className="profile-link profile-link--logout"
        onClick={() => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        }}
      >
        <LogoutOutlined />
        Log out
      </Link>
    ),
  },
];

const ProfileDropdown = () => (
  <Dropdown
    menu={{
      items,
    }}
    placement="bottomRight"
    trigger={["click"]}
  >
    <Avatar
      style={{
        backgroundColor: "#87d068",
        cursor: "pointer",
      }}
      icon={<UserOutlined />}
    />
  </Dropdown>
);
export default ProfileDropdown;
