import React from "react";
import { Outlet, redirect } from "react-router-dom";
import SideBar from "../MenuLayout/SideBar";
import Header from "../MenuLayout/Menu";

import "./Root.css";
import { Toaster } from "react-hot-toast";

function RootLayout() {
  return (
    <div style={{ display: "flex" }}>
      <Toaster position="top-right" reverseOrder={false} />
      <div>
        <SideBar />
      </div>
      <div className="header-outlet__content">
        <Header />
        <div className="outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default RootLayout;

export function loader() {
  const accessToken = localStorage.getItem("accessToken");

  if (!accessToken) {
    return redirect("/");
  }

  return null;
}
