import React, { useEffect } from "react";
import { Select } from "antd";

function HowDidYouHearSelect({
  defaultValue,
  value,
  setSelectedOption,
  className,
  disabled,
}) {
  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [setSelectedOption, defaultValue]);

  const options = [
    {
      label: "Facebook",
      value: "Facebook",
    },
    {
      label: "Google",
      value: "Google",
    },
    {
      label: "Our Team Member",
      value: "Our Team Member",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  return (
    <>
      <div className="country-content">
        <label className="country-label" htmlFor="roles">
          How Did You Hear About Us
        </label>
        <Select
          id="roles"
          options={options}
          value={value}
          className={className}
          defaultValue={defaultValue}
          onChange={(selectedOption) => setSelectedOption(selectedOption)}
          disabled={disabled}
        />
      </div>
    </>
  );
}

export default HowDidYouHearSelect;
