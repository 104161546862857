import "bootstrap/dist/css/bootstrap.css";
import { Badge, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Button, DatePicker } from "antd";
import { useEffect, useState } from "react";
import { ClientStatusEnum } from "../../../enum";
import { ClientSearchBar } from "../../../endpoint";
import ClientStatus from "../../ClientsList/ClientStatus";
import Input from "../../../utilities/Input/Input";
import Preloader from "../../../utilities/Preloader/Preloader";

const ClientTable = () => {
  const [data, setData] = useState([]);

  function getClientStatus(status) {
    if (status === ClientStatusEnum.NotVerified) {
      return "Not Verified";
    }
    if (status === ClientStatusEnum.DocumentsNotUpload) {
      return "Not uploaded documents";
    }
    if (status === ClientStatusEnum.Pending) {
      return "Pending";
    }
    if (status === ClientStatusEnum.Approved) {
      return "Approved";
    }
    if (status === ClientStatusEnum.Refused) {
      return "Refused";
    }
  }

  function getBadgeClass(status) {
    if (status === ClientStatusEnum.NotVerified) {
      return "not-verified-bg";
    }
    if (status === ClientStatusEnum.DocumentsNotUpload) {
      return "pending-bg";
    }
    if (status === ClientStatusEnum.Pending) {
      return "pending-bg";
    }
    if (status === ClientStatusEnum.Approved) {
      return "approved-bg";
    }
    if (status === ClientStatusEnum.Refused) {
      return "refused-bg";
    }
  }

  const [status, setStatus] = useState(ClientStatusEnum.Pending);
  const [current, setCurrent] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [createdDate, setCreatedDate] = useState();
  const [isReady, setIsReady] = useState(false);

  const navigate = useNavigate();

  const handleClientDetails = (e, clientId) => {
    navigate(`/dashboard/clients-list/client-details/${clientId}`);
  };

  useEffect(() => {
    ClientSearchBar(current, searchInput, status, createdDate, 10).then(
      (res) => {
        setData(res.data.clients);
        setIsReady(true);
      }
    );
  }, [current, searchInput, status, createdDate]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    setCurrent(1);
  };

  return (
    <div className="client-table">
      <div className="profile-details__input-content search-content search-content--width">
        <Input
          htmlFor={"search"}
          placeholder={"Search"}
          labelText={"Search"}
          type={"text"}
          id="search"
          value={searchInput}
          onChange={handleSearchInputChange}
        />
        <div className="form-input">
          <label className="input-label">Register Date</label>
          <DatePicker
            className="input"
            onChange={(e) => {
              const selectedDate = e?.format("YYYY-MM-DD");
              setCreatedDate(selectedDate);
            }}
            style={{ padding: "9.136px 1rem" }}
          />
        </div>
        <ClientStatus value={status} setValue={setStatus} />
      </div>
      <Preloader isReady={isReady}>
        <Table responsive style={{ width: "1472px" }}>
          <thead>
            <tr className="table-titles">
              <th className="th__width upper-mid-with">Company</th>
              <th className="th__width mid-width">Registration Number</th>
              <th className="th__width mid-width">First and Last Name</th>
              <th className="th__width">Email</th>
              <th className="th__width">Phone Number</th>
              <th className="th__width">Register Date</th>
              <th className="th__width">Status</th>
              <th className="th__width">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((client, index) => (
              <tr
                key={client.id}
                className={`tr-fontSize ${
                  index % 2 != 0 ? "table-striped" : ""
                }`}
              >
                <td>
                  <span className="align-middle">{client.name}</span>
                </td>
                <td>{client.companyRegistrationNumber}</td>
                <td>
                  {client.firstName} {client.lastName}
                </td>
                <td>{client.email}</td>
                <td>{client.mobileNo}</td>
                <td>{client.createdOn?.substring(0, 10)}</td>

                <td>
                  <Badge
                    pill
                    color="success"
                    className={`me-1 ${getBadgeClass(client.statusId)}`}
                  >
                    {getClientStatus(client.statusId)}
                  </Badge>
                </td>
                <td>
                  <div className="action-content">
                    <Button
                      onClick={(e) => handleClientDetails(e, client.id)}
                      className="action-button edit"
                      color="primary"
                      outline={true}
                    >
                      View Details
                    </Button>
                  </div>
                </td>
              </tr>
            ))}

            {data?.length === 0 && (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>
                  No results
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Preloader>
    </div>
  );
};

export default ClientTable;
