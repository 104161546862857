import React, { useEffect, useState } from "react";
import { Button, Modal, Select } from "antd";
import {
  ClientSearchBar,
  CreateInvoice,
  GetClientsDetails,
} from "../../endpoint";
import { v4 as uuid } from "uuid";
import { PlusOutlined } from "@ant-design/icons";
import InvoiceLineInputsWithCode from "./InvoiceLineInputsWithCode";
import toast from "react-hot-toast";
import Preloader from "../../utilities/Preloader/Preloader";

const CreateNewInvoiceModal = ({
  showModal,
  onCancel,

  invoiceLines,
  setInvoiceLines,
  setInvoices,
}) => {
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  const [descriptionError, setDescriptionError] = useState("");
  const [selectError, setSelectError] = useState("");
  const createdInvoiceType = 3;
  const [areClientDataReady, setAreClientDataReady] = useState(true);

  useEffect(() => {
    setInvoiceLines([
      {
        code: "",
        id: uuid(),
        description: ``,
        priceWithoutVAT: 0,
        vatRate: 0,
        priceWithVAT: 0,
        quantity: 1,
      },
    ]);
    ClientSearchBar(1, "", "", "").then((res) => {
      setClientList(res.data.clients);
    });
  }, []);

  const clientOptions = clientList?.map((client) => ({
    value: client.id,
    label: client.name,
  }));

  const onChange = (value) => {
    setAreClientDataReady(false);
    GetClientsDetails(value).then((res) => {
      setSelectedClient(res.data);
      setAreClientDataReady(true);
    });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleAddInvoiceLine = () => {
    const newInvoiceLine = {
      code: "",
      id: uuid(),
      description: "",
      priceWithoutVAT: 0,
      vatRate: 0,
      priceWithVAT: 0,
      quantity: 1,
    };
    setInvoiceLines((prev) => [...prev, newInvoiceLine]);
  };

  const createInvoice = () => {
    if (!selectedClient) {
      toast.error("Selecting a client is mandatory to generate an invoice.!", {
        duration: 2000,
        id: "textareaEmpty",
      });
      setSelectError("error-input");
    } else if (invoiceLines?.some((obj) => obj.description == "")) {
      toast.error("The description field must not remain blank. ", {
        duration: 2000,
        id: "invoiceConfirmFailed",
      });
      setDescriptionError("error-input");
    } else {
      setDescriptionError("");
      setSelectError("");
      CreateInvoice(selectedClient.id, createdInvoiceType, invoiceLines).then(
        (res) => {
          if (res.data.success) {
            setInvoices((prev) => [
              {
                companyName: selectedClient?.name,
                invoiceDate: new Date().toISOString(),
                totalAmount: invoiceLines?.reduce(
                  (acc, line) => acc + line.priceWithVAT,
                  0
                ),
                invoiceNo: res.data.invoiceNo,
                id: res.data.id,
              },
              ...prev,
            ]);
            toast.success("Invoice is successfully created!", {
              duration: 2000,
              id: "invoiceConfirmSuccess",
            });
          } else {
            toast.error("Failed to create invoice!", {
              duration: 2000,
              id: "invoiceConfirmFailed",
            });
          }
          onCancel();
        }
      );
    }
  };

  return (
    <Modal
      className="create-invoice-modal new-invoice"
      title={<>Create Invoice</>}
      centered
      open={showModal}
      onOk={createInvoice}
      onCancel={onCancel}
      okText={"Save"}
    >
      <div className="client-car-info">
        <div
          className="car-details-info-about-car client-info"
          style={{ width: "50%" }}
        >
          <h1>Client</h1>
          <Select
            showSearch
            placeholder="Select Client"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={filterOption}
            options={clientOptions}
            className={` ${!selectedClient ? selectError : ""}`}
          />
          {selectedClient && (
            <Preloader isReady={areClientDataReady} minHeight="17vh">
              <div className="client-car-info">
                <div
                  className="car-details-info-about-car client-info"
                  style={{ width: "50%" }}
                >
                  <div className="car-details-info-about-car__content">
                    <span className="details-title">Name</span>
                    <span>{selectedClient?.name}</span>
                  </div>
                  <span className="car-details-info-about-car__content-line"></span>
                  <div className="car-details-info-about-car__content">
                    <span className="details-title">Email</span>
                    <span>{selectedClient?.email}</span>
                  </div>
                  <span className="car-details-info-about-car__content-line"></span>
                  <div className="car-details-info-about-car__content">
                    <span className="details-title">Phone Number</span>
                    <span>{selectedClient?.mobileNo}</span>
                  </div>
                </div>

                <div
                  className="car-details-info-about-car car-info"
                  style={{ width: "50%" }}
                >
                  <div className="car-details-info-about-car__content">
                    <span className="details-title">City</span>
                    <span>{selectedClient?.city}</span>
                  </div>
                  <span className="car-details-info-about-car__content-line"></span>
                  <div className="car-details-info-about-car__content">
                    <span className="details-title">Address</span>
                    <span>{selectedClient?.address}</span>
                  </div>
                  <span className="car-details-info-about-car__content-line"></span>
                  <div className="car-details-info-about-car__content">
                    <span className="details-title">Zip Code</span>
                    <span>{selectedClient?.zipCode}</span>
                  </div>
                </div>
              </div>
            </Preloader>
          )}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{ display: "flex", gap: "1rem", margin: "2rem 0 0.5rem 0" }}
        >
          <span
            style={{
              width: "60px",
              fontWeight: "500",
              fontSize: "0.897rem",
              color: "#5e5873",
              fontWeight: "600",
            }}
          >
            Code
          </span>
          <span
            style={{
              width: "290px",
              fontWeight: "500",
              fontSize: "0.897rem",
              color: "#5e5873",
              fontWeight: "600",
            }}
          >
            Description
          </span>
          <span
            style={{
              width: "138px",
              fontWeight: "500",
              fontSize: "0.897rem",
              color: "#5e5873",
              fontWeight: "600",
            }}
          >
            Price Without VAT
          </span>
          <span
            style={{
              width: "138px",
              fontWeight: "500",
              fontSize: "0.897rem",
              color: "#5e5873",
              fontWeight: "600",
            }}
          >
            VAT Rate
          </span>
          <span
            style={{
              width: "138px",
              fontWeight: "500",
              fontSize: "0.897rem",
              color: "#5e5873",
              fontWeight: "600",
            }}
          >
            Price With VAT
          </span>
        </div>
        <div className="invoiceLines-inputs">
          {invoiceLines?.map((line, index) => {
            return (
              <InvoiceLineInputsWithCode
                invoiceLine={line}
                invoiceLines={invoiceLines}
                setInvoiceLines={setInvoiceLines}
                index={index}
                descriptionError={descriptionError}
                showModal={showModal}
              />
            );
          })}
        </div>
        <div className="total-price">
          <Button
            style={{ margin: "1rem 0 0 0", width: "fit-content" }}
            onClick={handleAddInvoiceLine}
            className="action-button edit"
            color="primary"
            outline="true"
          >
            <PlusOutlined
              style={{
                height: "fit-content",
                width: "17px",
                color: "var(--primary-color)",
              }}
            />
            Add new invoice line
          </Button>
          <p>
            Total:{" "}
            <span>
              €{invoiceLines?.reduce((acc, line) => acc + line.priceWithVAT, 0)}
            </span>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default CreateNewInvoiceModal;
