import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetDownloadInvoicePdfData } from "../../endpoint";
import Preloader from "../../utilities/Preloader/Preloader";

const InvoiceFile = () => {
  const [invoiceData, setInvoiceData] = useState();
  const { id } = useParams();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    GetDownloadInvoicePdfData(id).then((res) => {
      setInvoiceData(res.data);
      setIsReady(true);
    });
  }, [id]);

  return (
    <Preloader isReady={isReady} minHeight={"100vh"}>
      <iframe
        // id="myIframe"
        title="Interesting iFrame Title"
        src={`data:${invoiceData?.fileType};base64,${invoiceData?.base64Content}`}
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          width: "100%",
          height: "100%",
          border: "none",
          margin: "0",
          padding: "0",
          overflow: "hidden",
          zIndex: "999999",
        }}
      ></iframe>
    </Preloader>
  );
};

export default InvoiceFile;
