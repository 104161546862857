import "bootstrap/dist/css/bootstrap.css";
import "./Contact.css";
import { Trash } from "react-feather";
import { Table } from "reactstrap";

import { useNavigate } from "react-router-dom";

import { Button, Pagination, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { DeleteComment, GetContactList } from "../../endpoint";
import { toast } from "react-hot-toast";

const Contact = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [filter, setFilter] = useState();
  const [current, setCurrent] = useState(1);

  const handleContactMessage = (e, contactClientId) => {
    e.preventDefault();
    navigate(`contact-message/${contactClientId}`);
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    GetContactList(current).then((res) => {
      setData(res.data.contactMessages);
      setFilter(res.data.totalItems);
    });
  }, [current]);

  const handleDeleteComment = (contactClientId) => {
    DeleteComment(contactClientId).then((res) => {
      setData((prev) => {
        return prev.filter((item) => item.id !== contactClientId);
      });
      toast.success(res.data.message, {
        duration: 2000,
        id: "messageDeleted",
      });
    });
  };

  return (
    <>
      <Table responsive>
        <thead>
          <tr
            style={{ height: "100px !important", overflow: "hidden" }}
            className="table-titles"
          >
            <th>First Name</th>
            <th>Last Name</th>
            <th style={{ width: "21%" }}>Email</th>
            <th>Phone Number</th>
            <th style={{ width: "25%" }}>Message</th>
            <th className="contact-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((currentMessage, index) => {
            return (
              <tr
                key={currentMessage.id}
                className={`tr-fontSize ${
                  index % 2 != 0 ? "table-striped" : ""
                }`}
              >
                <td>
                  <span className="align-middle ">
                    {currentMessage.firstName}
                  </span>
                </td>
                <td>{currentMessage.lastName}</td>
                <td>{currentMessage.email}</td>
                <td>{currentMessage.phoneNumber}</td>
                <td>
                  <div className="contact-message">
                    {currentMessage.message}
                  </div>
                </td>
                <td>
                  <div className="action-content">
                    <Button
                      onClick={(e) =>
                        handleContactMessage(e, currentMessage.id)
                      }
                      className="action-button edit"
                      color="primary"
                      outline="true"
                    >
                      View
                    </Button>
                    <Popconfirm
                      title="Delete Comment"
                      description="Are you sure to delete this comment?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={(e) => handleDeleteComment(currentMessage.id)}
                    >
                      <Button
                        className="action-button warning"
                        color="warning"
                        outline="true"
                      >
                        <Trash className="me-50" size={15} /> Delete
                      </Button>
                    </Popconfirm>
                  </div>
                </td>
              </tr>
            );
          })}
          {data.length === 0 && (
            <tr>
              <td colSpan="6" style={{ textAlign: "center" }}>
                No results
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination current={current} onChange={onChange} total={filter} />
    </>
  );
};

export default Contact;
