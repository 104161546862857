import "bootstrap/dist/css/bootstrap.css";

import ReactCountryFlag from "react-country-flag";

import { Edit, Trash } from "react-feather";
import { Badge, Table } from "reactstrap";

import { MdDone, MdClose } from "react-icons/md";

import { useNavigate } from "react-router-dom";

import "./Services.css";
import Input from "../../utilities/Input/Input";

import { Button, Pagination, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { DeleteService, ServicesList } from "../../endpoint";
import StatusSearchSelect from "../Users/StatusSearchSelect";
import { toast } from "react-hot-toast";
import Preloader from "../../utilities/Preloader/Preloader";

const Services = () => {
  const [data, setData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [current, setCurrent] = useState(1);
  const [filter, setFilter] = useState();

  const [status, setStatus] = useState(0);

  const navigate = useNavigate();

  const handleAddServicesNavigate = () => {
    navigate("add-services");
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    setCurrent(1);
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    ServicesList(current, searchInput, status).then((res) => {
      setData(res?.data.services);
      setFilter(res?.data.totalItems);
      setIsReady(true);
    });
  }, [current, searchInput, status]);

  const handleServiceEditNavigate = (e, serviceId) => {
    e.preventDefault();
    navigate(`/dashboard/services/edit-service/${serviceId}`);
  };

  const handleServiceDelete = (serviceId) => {
    DeleteService(serviceId).then((res) => {
      if (res.data.success) {
        toast.success(res.data.message, {
          duration: 2000,
          id: "serviceDeletedSuccessfully",
        });
      }
      setData((prev) => {
        return prev.filter((item) => item.id !== serviceId);
      });
    });
  };

  return (
    <Preloader isReady={isReady}>
      <div className="profile-details__input-content search-content search-content--width">
        <Input
          htmlFor={"search"}
          placeholder={"Search"}
          labelText={"Search"}
          type={"text"}
          id="search"
          value={searchInput}
          onChange={handleSearchInputChange}
        />
        <StatusSearchSelect value={status} setValue={setStatus} />
      </div>
      <Table responsive>
        <thead>
          <tr className="table-titles">
            <th className="thh__width">Country</th>
            <th className="thh__width">Price</th>
            <th className="thh__width">Standard passenger car</th>
            <th className="thh__width">Minivan</th>
            <th className="thh__width">SUV</th>
            <th className="thh__width">Status</th>
            <th className="thh__width">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((service, index) => {
            return (
              <tr
                key={service.id}
                className={`tr-fontSize ${
                  index % 2 != 0 ? "table-striped" : ""
                }`}
              >
                <td>
                  <span className="align-middle country-flag">
                    <ReactCountryFlag countryCode={service.countryCode} svg />{" "}
                    {service.countryName}
                  </span>
                </td>
                <td>{service.price}€</td>
                <td>
                  {service.standardPassengerCar ? (
                    <MdDone className="done-icon" />
                  ) : (
                    <MdClose className="not-icon" />
                  )}
                </td>
                <td>
                  {service.minivan ? (
                    <MdDone className="done-icon" />
                  ) : (
                    <MdClose className="not-icon" />
                  )}
                </td>
                <td>
                  {service.suv ? (
                    <MdDone className="done-icon" />
                  ) : (
                    <MdClose className="not-icon" />
                  )}
                </td>
                <td>
                  {service.statusId === 1 ? (
                    <Badge pill color="success" className="me-1 active-bg">
                      Active
                    </Badge>
                  ) : (
                    <Badge pill color="primary" className="me-1 passive-bg">
                      Passive
                    </Badge>
                  )}
                </td>
                <td>
                  <div className="action-content">
                    <Button
                      className="action-button edit"
                      color="primary"
                      outline="true"
                      onClick={(e) => handleServiceEditNavigate(e, service.id)}
                    >
                      <Edit className="me-50" size={15} /> Edit
                    </Button>
                    <Popconfirm
                      title="Delete Service"
                      description="Are you sure to delete this service?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={(e) => handleServiceDelete(service.id)}
                    >
                      <Button
                        className="action-button warning"
                        color="warning"
                        outline="true"
                      >
                        <Trash className="me-50" size={15} /> Delete
                      </Button>
                    </Popconfirm>
                  </div>
                </td>
              </tr>
            );
          })}
          {data?.length === 0 && (
            <tr>
              <td
                colSpan="7"
                style={{
                  textAlign: "center",
                  color: "#6a717c",
                  padding: "1rem 0px 1rem 0px",
                }}
              >
                No results
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination current={current} onChange={onChange} total={filter} />
      <Button
        onClick={handleAddServicesNavigate}
        className="add-services__button"
        type="default"
      >
        Add Services
      </Button>
    </Preloader>
  );
};

export default Services;
