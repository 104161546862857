import { useContext } from "react";
import { TbError404 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";

function ErrorPage() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/dashboard/home");
  };
  const isLoggedIn = useContext(AuthContext);

  return (
    <>
      <div className="page-not-found__error">
        <p className="page-not-found__text">
          <TbError404 className="icon-404" /> Page Not Found!
        </p>
        <button className="page-not-found__button" onClick={handleNavigate}>
          Back to the {isLoggedIn.data.success ? "Dashboard" : "Login"}
        </button>
      </div>
    </>
  );
}

export default ErrorPage;
