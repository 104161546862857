import React, { useState } from "react";
import { Button, Modal } from "antd";
import { CiSquareInfo } from "react-icons/ci";

const InvoiceConfirmationModal = ({ showModal, confirmInvoice, onCancel }) => {
  return (
    <Modal
      className="invoice-confirmation-modal"
      title={
        <>
          <CiSquareInfo />
          Confirm invoice
        </>
      }
      centered
      open={showModal}
      onOk={confirmInvoice}
      onCancel={onCancel}
      width={1000}
      okText={"Confirm"}
    >
      <p>
        Please carefully review the details of the invoice before confirming.
        Ensure all information is accurate and meets the necessary criteria.
        Once confirmed, the action cannot be undone.
      </p>
    </Modal>
  );
};

export default InvoiceConfirmationModal;
