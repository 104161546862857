import React, { useEffect, useState } from "react";
import Input from "../../utilities/Input/Input";

import { useNavigate, useParams } from "react-router-dom";

import "./ContactMessage.css";
import { DeleteComment, GetContactDetails } from "../../endpoint";
import { Popconfirm } from "antd";
import { toast } from "react-hot-toast";

function ContactMessage() {
  const navigate = useNavigate();

  const { contactClientId } = useParams();

  const [contactClientDetails, setContactClientDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleBackNavigate = () => {
    navigate("/dashboard/contact");
  };

  useEffect(() => {
    GetContactDetails(contactClientId)
      .then((res) => {
        setContactClientDetails(res.data);
      })
  }, [contactClientId]);

  const handleDeleteComment = (contactClientId) => {
    DeleteComment(contactClientId)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            duration: 3000,
            id: "messageSentSuccessfuly",
          });
          navigate("/dashboard/contact");
        }
      })
    
  };

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title">
          <h2>Contact Message</h2>
        </div>
        <div className="profile-details__inputs">
          <div className="profile-details__input-content">
            <Input
              htmlFor={"firstName"}
              labelText={"First Name"}
              type={"text"}
              id="firstName"
              value={contactClientDetails.firstName}
              disabled={"disabled"}
            />
            <Input
              htmlFor={"lastName"}
              labelText={"Last Name"}
              type={"text"}
              id="lastName"
              value={contactClientDetails.lastName}
              disabled={"disabled"}
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"email"}
              labelText={"Email"}
              type={"email"}
              id="email"
              value={contactClientDetails.email}
              disabled={"disabled"}
            />
            <Input
              htmlFor={"phoneNum"}
              labelText={"Phone Number"}
              type={"tel"}
              id="phoneNum"
              value={contactClientDetails.phoneNumber}
              disabled={"disabled"}
            />
          </div>
          <div className="profile-details__input-content">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <label htmlFor="message">Message</label>
              <textarea
                className="contact-form__textarea"
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Write message"
                disabled
                value={contactClientDetails.message}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="profile-details__buttons">
          <Popconfirm
            title="Delete Comment"
            description="Are you sure to delete this comment?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleDeleteComment(contactClientId)}
          >
            <button className="save-changes delete-button">Delete</button>
          </Popconfirm>
          <button onClick={handleBackNavigate} className="discard">
            Back
          </button>
        </div>
      </div>
    </>
  );
}

export default ContactMessage;
