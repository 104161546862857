import { Drawer } from "antd";

import { AlignLeftOutlined } from "@ant-design/icons";

import "./Menu.css";
import { useContext, useState } from "react";
import SideBar from "./SideBar";
import { AuthContext } from "../../Context/AuthContext";
import ProfileDropdown from "./ProfileDopdown";

const Header = () => {
  const { data } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="layout">
        <header className="layout__header">
          <AlignLeftOutlined className="layout__trigger" onClick={showDrawer} />
          <Drawer placement="left" onClose={onClose} open={open}>
            <SideBar
              setOpen={onClose}
              onClose={onClose}
              isOpen={open && "block"}
            />
          </Drawer>

          <div className="layout__avatar-container">
            <div className="layout__user-name">
              <span style={{ fontWeight: "600" }}>
                {data.firstName} {data.lastName}
              </span>
              <span style={{ fontSize: "12px" }}>
                {data.role === 1 ? "Administrator" : "User"}
              </span>
            </div>
            <ProfileDropdown />
          </div>
        </header>
      </div>
    </>
  );
};
export default Header;
