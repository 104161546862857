import React, { createContext, useEffect, useState } from "react";
import { GetCurrentUser } from "../endpoint";

// Create the context
export const AuthContext = createContext({
  data: "",
  setData: () => {},
  fetchCurrentClient: () => {},
  isLoggedIn: true,
  setIsLoggedIn: () => {},
});

// Create a provider component
export const AuthContextProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState();

  const fetchCurrentClient = () => {
    GetCurrentUser().then((res) => {
      setData(res.data);
      if (res.data.success) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  };

  useEffect(() => {
    fetchCurrentClient();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        data: data,
        setData: setData,
        fetchCurrentClient: fetchCurrentClient,
        isLoggedIn,
        setIsLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
