import "bootstrap/dist/css/bootstrap.css";

import { Edit, Trash } from "react-feather";
import { Table, Badge } from "reactstrap";
import { useNavigate } from "react-router-dom";

import "./UsersList.css";
import { Button, Pagination, Popconfirm } from "antd";
import Input from "../../utilities/Input/Input";
import { useEffect, useState } from "react";
import { DeleteUser, GetUsers } from "../../endpoint";
import StatusSearchSelect from "./StatusSearchSelect";
import { toast } from "react-hot-toast";

const UsersList = () => {
  const [data, setData] = useState([]);

  const [filter, setFilter] = useState();
  const [status, setStatus] = useState(0);

  const [current, setCurrent] = useState(1);

  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  const handleUserEditNavigate = (e, userId) => {
    e.preventDefault();
    navigate(`/dashboard/users-list/edit-user/${userId}`);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    setCurrent(1);
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    GetUsers(current, searchInput, status).then((res) => {
      setData(res.data.users);
      setFilter(res.data.totalItems);
    });
  }, [current, searchInput, status]);

  const handleUserDelete = (userId) => {
    DeleteUser(userId).then((res) => {
      setData((prev) => {
        return prev.filter((item) => item.userId !== userId);
      });
      toast.success(res.data.message, {
        duration: 2000,
        id: "userDeletedSuccessfuly",
      });
      setStatus(0);
    });
  };

  return (
    <>
      <div className="profile-details__input-content search-content">
        <Input
          htmlFor={"search"}
          placeholder={"Search"}
          labelText={"Search"}
          type={"text"}
          id="search"
          value={searchInput}
          onChange={handleSearchInputChange}
        />
        <StatusSearchSelect value={status} setValue={setStatus} />
      </div>
      <Table responsive>
        <thead>
          <tr className="table-titles">
            <th>Username</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th className="userList-role">Role</th>
            <th>Status</th>
            <th className="userList-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((user, index) => {
            return (
              <tr
                key={user.userId}
                className={`tr-fontSize ${
                  index % 2 != 0 ? "table-striped" : ""
                }`}
              >
                <td>
                  <span className="align-middle ">{user.userName}</span>
                </td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.phoneNumber}</td>
                <td>{user.role === 1 ? "Administrator" : "User"}</td>
                <td>
                  {user.status === 1 ? (
                    <Badge pill color="success" className="me-1 active-bg">
                      Active
                    </Badge>
                  ) : (
                    <Badge pill color="primary" className="me-1 passive-bg">
                      Passive
                    </Badge>
                  )}
                </td>
                <td>
                  <div className="action-content">
                    <Button
                      onClick={(e) => handleUserEditNavigate(e, user.userId)}
                      className="action-button edit"
                      color="primary"
                      outline="true"
                    >
                      <Edit className="me-50" size={15} /> Edit
                    </Button>
                    <Popconfirm
                      title="Delete User"
                      description="Are you sure to delete this user?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={(e) => handleUserDelete(user.userId)}
                    >
                      <Button
                        className="action-button warning"
                        color="warning"
                        outline="true"
                      >
                        <Trash className="me-50" size={15} /> Delete
                      </Button>
                    </Popconfirm>
                  </div>
                </td>
              </tr>
            );
          })}
          {data.length === 0 && (
            <tr>
              <td colSpan="7" style={{ textAlign: "center" }}>
                No results
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination current={current} onChange={onChange} total={filter} />
    </>
  );
};

export default UsersList;
