import React from "react";
import { Select } from "antd";
import { ClientStatusEnum } from "../../enum";

function ClientStatus({ setValue, value }) {
  const options = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Not Verified",
      value: ClientStatusEnum.NotVerified,
    },
    {
      label: "Not uploaded documents",
      value: ClientStatusEnum.DocumentsNotUpload,
    },
    {
      label: "Pending",
      value: ClientStatusEnum.Pending,
    },
    {
      label: "Approved",
      value: ClientStatusEnum.Approved,
    },
    {
      label: "Refused",
      value: ClientStatusEnum.Refused,
    },
  ];

  return (
    <>
      <div className="country-content">
        <label className="country-label" htmlFor="clientStatus">
          Status
        </label>
        <Select
          allowClear
          id="clientStatus"
          options={options}
          defaultValue="Pending"
          value={value}
          onChange={(e) => setValue(e)}
        />
      </div>
    </>
  );
}

export default ClientStatus;
