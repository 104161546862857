import React, { useContext, useEffect, useState } from "react";
import Input from "../../utilities/Input/Input";
import StatusSelect from "../Users/StatusSelect";

import "./ProfileDetails.css";
import { GetUserDetails, UpdateUserDetails } from "../../endpoint";
import { AuthContext } from "../../Context/AuthContext";
import UserRole from "../Users/UserRole";
import { toast } from "react-hot-toast";
import { Button } from "antd";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function ProfileDetails() {
  const ctx = useContext(AuthContext);

  const [error, setError] = useState("");
  const [editable, setEditable] = useState(false);
  const [userDetails, setUserDetails] = useState({
    userName: "",
    firstName: " ",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    address: "",
    role: 0,
    status: 0,
  });

  useEffect(() => {
    GetUserDetails(ctx?.data.userId).then((res) => {
      setUserDetails(res.data);
    });
  }, [ctx?.data.userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setEditable(true);
  };

  const handleDiscard = () => {
    GetUserDetails(ctx?.data.userId).then((res) => {
      setUserDetails(res.data);
    });
    setEditable(false);
  };

  const handleSave = () => {
    if (
      userDetails.userName === "" ||
      userDetails.firstName === "" ||
      userDetails.lastName === "" ||
      userDetails.email === "" ||
      userDetails.role === 0 ||
      userDetails.status === 0
    ) {
      setError("input-red");
      toast.error("Inputs cannot be empty!", {
        duration: 2000,
        id: "inputsCannotBeEmpty",
      });
      return;
    } else {
      UpdateUserDetails({
        userId: ctx?.data.userId,
        userName: userDetails.userName,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        phoneNumber: userDetails.phoneNumber,
        city: userDetails.city,
        address: userDetails.address,
        role: userDetails.role,
        status: userDetails.status,
      }).then((res) => {
        if (res.data.success) {
          ctx.setData((prevData) => ({
            ...prevData,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            role: userDetails.role,
          }));
          toast.success(res.data.message, {
            duration: 2000,
            id: "clientUpdatedSuccessfuly",
          });
        }
      });
    }
    setEditable(false);
  };

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title client-details--title">
          <h2>Profile Details</h2>
          {!editable && <Button onClick={handleEdit}>Edit</Button>}
        </div>
        <div className="profile-details__inputs">
          <div className="profile-details__input-content">
            <Input
              htmlFor={"userName"}
              labelText={
                <>
                  Username <RequiredStar />
                </>
              }
              type={"text"}
              id="userName"
              name="userName"
              value={userDetails.userName}
              onChange={handleChange}
              className={userDetails.userName === "" ? error : ""}
              disabled={!editable ? true : false}
            />
            <Input
              htmlFor={"email"}
              labelText={
                <>
                  Email <RequiredStar />
                </>
              }
              type={"email"}
              id="email"
              name="email"
              value={userDetails.email}
              onChange={handleChange}
              className={userDetails.email === "" ? error : ""}
              disabled={!editable ? true : false}
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"firstName"}
              labelText={
                <>
                  First Name <RequiredStar />
                </>
              }
              type={"text"}
              id="firstName"
              name="firstName"
              value={userDetails.firstName}
              onChange={handleChange}
              className={userDetails.firstName === "" ? error : ""}
              disabled={!editable ? true : false}
            />
            <Input
              htmlFor={"lastName"}
              labelText={
                <>
                  Last Name
                  <RequiredStar />
                </>
              }
              type={"text"}
              id="lastName"
              name="lastName"
              value={userDetails.lastName}
              onChange={handleChange}
              className={userDetails.lastName === "" ? error : ""}
              disabled={!editable ? true : false}
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"city"}
              labelText={"City"}
              type={"text"}
              id="city"
              name="city"
              value={userDetails.city}
              onChange={handleChange}
              disabled={!editable ? true : false}
            />
            <Input
              htmlFor={"address"}
              labelText={"Address"}
              type={"text"}
              id="address"
              name="address"
              value={userDetails.address}
              onChange={handleChange}
              disabled={!editable ? true : false}
            />
          </div>
          <div className="profile-details__input-content">
            <Input
              htmlFor={"phoneNumber"}
              labelText={"Phone Number"}
              type={"tel"}
              id="phoneNumber"
              name="phoneNumber"
              value={userDetails.phoneNumber}
              onChange={handleChange}
              disabled={!editable ? true : false}
            />
            <UserRole
              onChange={setUserDetails}
              value={userDetails.role}
              className={userDetails.role === 0 ? error + " error-border" : ""}
              disabled={!editable ? true : false}
              required={true}
            />
          </div>
          <div
            className="profile-details__input-content"
            style={{ width: "calc(50% - 1.5rem)" }}
          >
            <StatusSelect
              value={userDetails.status}
              onChange={setUserDetails}
              className={
                userDetails.status === 0 ? error + " error-border" : ""
              }
              disabled={!editable ? true : false}
            />
          </div>
        </div>
        {editable && (
          <div className="profile-details__buttons">
            <button className="save-changes" onClick={handleSave}>
              Save changes
            </button>
            <button className="discard" onClick={handleDiscard}>
              Discard
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileDetails;
