import "bootstrap/dist/css/bootstrap.css";
import { Pagination, Tooltip } from "antd";
import { Badge, Table } from "reactstrap";
import { Button } from "antd";
import { useEffect, useState } from "react";
import {
  CreateInvoice,
  DownloadInvoice,
  GetInvoicesList,
  InvoiceConfirmPaid,
} from "../../endpoint";
import Preloader from "../../utilities/Preloader/Preloader";
import "./Invoices.css";
import InvoiceConfirmationModal from "../Modals/InvoiceConfirmationModal";
import toast from "react-hot-toast";
import { MdOutlineFileDownload } from "react-icons/md";
import CreateInvoiceModal from "../Modals/CreateInvoiceModal";

const ProformaInvoices = () => {
  const [data, setData] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [current, setCurrent] = useState(1);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [selectedCarInvoice, setSelectedCarInvoice] = useState();
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [invoiceLines, setInvoiceLines] = useState([]);
  const [descriptionError, setDescriptionError] = useState("");
  const [createNewInvoice, setCreateNewInvoice] = useState();
  const [carId, setCarId] = useState();
  const [clientId, setClientId] = useState();

  useEffect(() => {
    GetInvoicesList(current).then((res) => {
      setData(res.data.clientInvoices);
      setTotalItems(res.data.totalItems);
      setIsReady(true);
    });
  }, [current]);

  const onChange = (page) => {
    setCurrent(page);
  };

  const handleInvoiceConfirmPaid = () => {
    InvoiceConfirmPaid(selectedInvoice).then((res) => {
      if (res.data.success) {
        toast.success("Invoice confirmed successfully!", {
          duration: 2000,
          id: "invoiceConfirmSuccess",
        });
        setData((prevData) =>
          prevData.map((item) =>
            item.id === selectedInvoice
              ? {
                  ...item,
                  isPaid: true,
                  confirmedDate: new Date().toISOString(),
                }
              : item
          )
        );
      } else {
        toast.error("Failed to confirm invoice!", {
          duration: 2000,
          id: "invoiceConfirmFailed",
        });
      }
      setShowConfirmationModal(false);
    });
  };

  function getInvoiceStatus(status) {
    if (status) {
      return "Yes";
    }
    if (!status) {
      return "No";
    }
  }

  function getInvoiceClass(status) {
    if (status) {
      return "approved-bg";
    }
    if (!status) {
      return "pending-bg";
    }
  }

  const handleCreateInvoice = () => {
    const createdInvoiceType = createNewInvoice ? 2 : 1;
    if (invoiceLines?.some((obj) => obj.description == "")) {
      setDescriptionError("error-input");
      toast.error("The description field must not remain blank.", {
        duration: 2000,
        id: "invoiceConfirmFailed",
      });
    } else {
      CreateInvoice(
        clientId,
        createdInvoiceType,
        invoiceLines,
        selectedInvoice,
        carId
      ).then((res) => {
        if (res.data.success) {
          setData((prevData) =>
            prevData.map((item) =>
              item.id === selectedInvoice
                ? {
                    ...item,
                    hasCreatedInvoice:
                      createdInvoiceType === 1 ? true : item.hasCreatedInvoice,
                    hasNewInvoice:
                      createdInvoiceType === 2 ? true : item.hasNewInvoice,
                  }
                : item
            )
          );

          toast.success("Invoice is successfully created!", {
            duration: 2000,
            id: "invoiceConfirmSuccess",
          });
        } else {
          toast.error("Failed to create invoice!", {
            duration: 2000,
            id: "invoiceConfirmFailed",
          });
        }
        setShowCreateInvoiceModal(false);
      });
    }
  };

  return (
    <div className="profile-details">
      <div className="profile-details__title">
        <h2>Proforma Invoices</h2>
        <Preloader isReady={isReady}>
          <div className="profile-details__input-content search-content search-content--width">
            <InvoiceConfirmationModal
              showModal={showConfirmationModal}
              confirmInvoice={handleInvoiceConfirmPaid}
              onCancel={() => {
                setShowConfirmationModal(false);
              }}
            />
            <CreateInvoiceModal
              showModal={showCreateInvoiceModal}
              createInvoice={handleCreateInvoice}
              onCancel={() => {
                setShowCreateInvoiceModal(false);
              }}
              selectedCarOfInvoice={selectedCarInvoice}
              invoiceLines={invoiceLines}
              setInvoiceLines={setInvoiceLines}
              descriptionError={descriptionError}
              createNewInvoice={createNewInvoice}
              setClientId={setClientId}
              setCarId={setCarId}
            />
          </div>
          <Table responsive className="invoices-table proforma-invoices">
            <thead>
              <tr className="table-titles">
                <th className="th__width upper-mid-with">Invoice No</th>
                <th className="th__width mid-width">Company Name</th>
                <th className="th__width mid-width">Invoice Date</th>
                <th className="th__width">Car Price</th>
                <th className="th__width">Bank Fee</th>
                <th className="th__width">Transport</th>
                <th className="th__width">Auction Service Fee</th>
                <th className="th__width">Total Price</th>
                <th className="th__width">Confirmed Date</th>
                <th className="th__width">Paid</th>
                <th className="th__width"></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((data, index) => {
                return (
                  <tr
                    key={data?.id}
                    className={`tr-fontSize ${
                      index % 2 != 0 ? "table-striped" : ""
                    }`}
                  >
                    <td>
                      <span className="align-middle ">{data?.invoiceNo}</span>
                    </td>
                    <td>{data?.companyName}</td>
                    <td>{data?.invoiceDate?.substring(0, 10)}</td>
                    <td>€{data?.carPrice}</td>
                    <td>€{data?.bankFee}</td>
                    <td>€{data?.transport}</td>
                    <td>€{data?.auctionServiceFee}</td>
                    <td>€{data?.totalAmount}</td>
                    <td>{data?.confirmedDate?.substring(0, 10)}</td>
                    <td>
                      <Badge
                        pill
                        color="success"
                        className={`me-1 ${getInvoiceClass(data?.isPaid)}`}
                      >
                        {getInvoiceStatus(data?.isPaid)}
                      </Badge>
                    </td>

                    <td>
                      <div className="action-content">
                        <Tooltip title="Download">
                          <MdOutlineFileDownload
                            style={{
                              cursor: "pointer",
                              height: "1.2rem",
                              width: "1.5rem",
                            }}
                            onClick={() => {
                              DownloadInvoice(data.id, data.carId);
                            }}
                          />
                        </Tooltip>{" "}
                        {!data?.hasCreatedInvoice && (
                          <Button
                            className="action-button edit"
                            color="primary"
                            outline="true"
                            onClick={() => {
                              setShowCreateInvoiceModal(true);
                              setSelectedCarInvoice(data?.carAuctionId);
                              setSelectedInvoice(data?.id);
                              setCreateNewInvoice(false);
                            }}
                          >
                            Create invoice
                          </Button>
                        )}
                        {!data?.hasNewInvoice && (
                          <Button
                            className="cars-button"
                            onClick={() => {
                              setShowCreateInvoiceModal(true);
                              setSelectedCarInvoice(data?.carAuctionId);
                              setSelectedInvoice(data?.id);
                              setCreateNewInvoice(true);
                            }}
                          >
                            Create new invoice
                          </Button>
                        )}
                        {!data.isPaid && (
                          <Button
                            onClick={() => {
                              setShowConfirmationModal(true);
                              setSelectedInvoice(data?.id);
                            }}
                            className="action-button edit"
                            color="primary"
                            outline="true"
                          >
                            Confirm
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
              {data?.length === 0 && (
                <tr>
                  <td colSpan="11" style={{ textAlign: "center" }}>
                    No results
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination
            current={current}
            onChange={onChange}
            total={totalItems}
          />
        </Preloader>
      </div>
    </div>
  );
};

export default ProformaInvoices;
