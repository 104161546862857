import React, { useEffect, useState } from "react";
import { Select } from "antd";

import "./CountrySelect.css";
import { GetCountries } from "../../endpoint";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function CountrySelect({
  defaultValue,
  value,
  setSelectedCountry,
  className,
  disabled,
  required = false,
}) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    GetCountries().then((res) => {
      setCountries(res.data.countries);
      setSelectedCountry(defaultValue);
    });
  }, [defaultValue, setSelectedCountry]);

  const countryOptions = countries.map((country) => ({
    value: country.value,
    label: country.text,
  }));

  return (
    <>
      <div className="country-content">
        <label className="country-label" htmlFor="country">
          Country {required && <RequiredStar />}
        </label>
        <Select
          className={className}
          showSearch={true}
          id="country"
          options={countryOptions}
          value={value}
          defaultValue={defaultValue}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onChange={(selectedOption) => setSelectedCountry(selectedOption)}
          disabled={disabled}
        />
      </div>
    </>
  );
}

export default CountrySelect;
