import React, { useEffect, useState } from "react";
import Input from "../../utilities/Input/Input";

import { Radio } from "antd";
import "./EditService.css";
import { useNavigate, useParams } from "react-router-dom";
import CountrySelect from "../Profile/CountrySelect";
import { Checkbox } from "antd";
import { GetServiceDetails, UpdateService } from "../../endpoint";
import { toast } from "react-hot-toast";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function EditService() {
  const [service, setService] = useState({
    countryCode: "",
    price: 0,
    standardPassengerCar: "",
    minivan: "",
    suv: "",
    statusId: 0,
  });

  const [error, setError] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");

  const { serviceId } = useParams();

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/dashboard/services");
  };

  useEffect(() => {
    GetServiceDetails(serviceId).then((res) => {
      setService(res?.data);
      setStatus(res?.data.statusId);
    });
  }, [serviceId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setService((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const [status, setStatus] = useState();

  const onChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSave = () => {
    if (service.price === "") {
      toast.error("Input field cannot be empty!", {
        duration: 2000,
        id: "inputCannotBeEmpty",
      });
      setError("input-red");
    } else {
      UpdateService({
        id: serviceId,
        countryCode:
          selectedCountry === "" ? service.countryCode : selectedCountry,
        price: +service.price,
        standardPassengerCar: service.standardPassengerCar,
        minivan: service.minivan,
        suv: service.suv,
        statusId: status,
      }).then((res) => {
        if (res.data.success) {
          navigate("/dashboard/services");
          toast.success(res.data.message, {
            duration: 2000,
            id: "serviceUpdatedSuccessfuly",
          });
        }
      });
    }
  };

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title">
          <h2>Edit Service</h2>
        </div>
        <div className="profile-details__inputs">
          <div className="profile-details__input-content">
            <CountrySelect
              defaultValue={service.countryCode}
              value={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              required={true}
            />
            <Input
              htmlFor={"price"}
              labelText={
                <>
                  Price <RequiredStar />
                </>
              }
              type={"number"}
              id="price"
              value={service.price}
              name="price"
              onChange={handleChange}
              className={service.price === "" ? error : ""}
            />
          </div>

          <div className="profile-details__input-content ">
            <div className="checkbox-container">
              <Checkbox
                defaultChecked={service.standardPassengerCar}
                checked={service.standardPassengerCar}
                onChange={handleChange}
                name="standardPassengerCar"
              >
                Standard Passenger Car
              </Checkbox>
              <Checkbox
                defaultChecked={service.minivan}
                checked={service.minivan}
                onChange={handleChange}
                name="minivan"
              >
                Minivan
              </Checkbox>
              <Checkbox
                defaultChecked={service.suv}
                checked={service.suv}
                onChange={handleChange}
                name="suv"
              >
                SUV / Off-road
              </Checkbox>
            </div>
            <Radio.Group onChange={onChange} value={status}>
              <Radio value={1}>Active</Radio>
              <Radio value={2}>Passive</Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="profile-details__buttons">
          <button onClick={handleSave} className="save-changes">
            Save changes
          </button>
          <button onClick={handleCancel} className="discard">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default EditService;
