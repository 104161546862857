import React from "react";

import "./Input.css";

function Input({ htmlFor, type, placeholder, labelText, value, id, onChange, name, className, disabled, required }) {
  return (
    <>
      <div className="form-input">
        <label className="input-label" htmlFor={htmlFor}>
          {labelText}
        </label>
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          id={id}
          onChange={onChange}
          name={name}
          className={`input `+ className}
          disabled={disabled}
          required={required}
        />
      </div>
    </>
  );
}

export default Input;
