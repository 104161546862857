import React, { useState } from "react";
import { Button, Modal } from "antd";
import { CiSquareInfo } from "react-icons/ci";

const ReceiveConfirmationModal = ({
  showReceiveModal,
  updateCarStatusId,
  onCancel,
}) => {
  return (
    <Modal
      className="receive-confirmation-modal"
      title={
        <>
          <CiSquareInfo />
          Verify car reception
        </>
      }
      centered
      open={showReceiveModal}
      onOk={updateCarStatusId}
      onCancel={onCancel}
      width={1000}
      okText={"Accept"}
    >
      <p>Ensure thorough checks before confirmation.</p>
    </Modal>
  );
};

export default ReceiveConfirmationModal;
