import "bootstrap/dist/css/bootstrap.css";
import { Edit } from "react-feather";
import { Table } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { GetPriceList } from "../../endpoint";
import { useEffect, useState } from "react";
import Preloader from "../../utilities/Preloader/Preloader";

const PriceList = () => {
  const [priceData, setPriceData] = useState({
    bankFee: "",
    auctionServiceFee: "",
    exportDeclarationFee: "",
    transport: "",
    auctionServicefeeOver10k: "",
  });
  const [isReady, setIsReady] = useState(false);

  const navigate = useNavigate();

  const handlePriceListEdit = (e) => {
    e.preventDefault();
    navigate("edit");
  };

  useEffect(() => {
    GetPriceList()
      .then((res) =>
        setPriceData({
          bankFee: res.data.bankFee,
          auctionServiceFee: res.data.auctionServiceFee,
          exportDeclarationFee: res.data.exportDeclarationFee,
          transport: res.data.transport,
          auctionServicefeeOver10k: res.data.auctionServicefeeOver10k,
        })
      )
      .finally(() => setIsReady(true));
  }, []);

  return (
    <Preloader isReady={isReady}>
      <Table responsive>
        <thead>
          <tr className="table-titles">
            <th style={{ width: "16%" }}>Bank fee</th>
            <th style={{ width: "16%" }}>Auction service fee</th>
            {/* <th style={{ width: "16%" }}>Export declaration</th> */}
            {/* <th style={{ width: "16%" }}>Transport</th> */}
            <th style={{ width: "20%" }}>Auction Service Fee Over 20k</th>
            <th style={{ width: "17%" }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr className="tr-fontSize">
            <td>
              <span className="align-middle ">
                € {priceData.bankFee.toLocaleString()}
              </span>
            </td>
            <td>€ {priceData.auctionServiceFee?.toLocaleString()}</td>
            {/* <td>€ {priceData.exportDeclarationFee?.toLocaleString()}</td> */}
            {/* <td>€ {priceData.transport?.toLocaleString()}</td> */}
            <td>€ {priceData.auctionServicefeeOver10k.toLocaleString()}</td>
            <td>
              <div className="action-content" style={{ display: "block" }}>
                <Button
                  onClick={handlePriceListEdit}
                  className="action-button edit"
                  color="primary"
                  outline="true"
                >
                  <Edit className="me-50" size={15} /> Edit
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </Preloader>
  );
};

export default PriceList;
