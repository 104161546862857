import Input from "../../utilities/Input/Input";
import React, { useContext, useState } from "react";

import "./ChangePassword.css";
import { UserChangePassword } from "../../endpoint";
import { AuthContext } from "../../Context/AuthContext";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function ChangePassword() {
  const ctxId = useContext(AuthContext);

  const navigate = useNavigate();

  const [dataForm, setDataForm] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [currentPasswordIsValid, setCurrentPasswordIsValid] = useState({
    valid: true,
  });

  const [newPasswordIsValid, setNewPasswordIsValid] = useState({
    valid: true,
  });

  const [confirmNewPasswordIsValid, setConfirmNewPasswordIsValid] = useState({
    valid: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "currentPassword") {
      setCurrentPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: "Current password cannot be empty",
      }));
    }

    if (name === "currentPassword") {
      setCurrentPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 8),
        message: "Current password must be at least 8 characters",
      }));
    }

    if (name === "password") {
      setNewPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: "Please enter a valid password",
      }));
    }

    if (name === "password") {
      setNewPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: !(value.trim().length < 8),
        message: "Password must be at least 8 characters",
      }));
    }

    if (name === "confirmPassword") {
      setConfirmNewPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: value.trim().length !== 0,
        message: "Please enter a valid confirm password",
      }));

      setConfirmNewPasswordIsValid((prevIsValid) => ({
        ...prevIsValid,
        valid: dataForm.password === value,
        message: "Password do not match",
      }));
    }
  };

  const handleSubmit = () => {
    if (dataForm.currentPassword.trim().length === 0) {
      setCurrentPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Current password cannot be empty",
        };
      });
    } else if (dataForm.currentPassword.trim().length < 8) {
      setCurrentPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Current password must be at least 8 characters",
        };
      });
    } else {
      setCurrentPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.password.trim().length === 0) {
      setNewPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Please enter a valid password",
        };
      });
    } else if (dataForm.password.trim().length < 8) {
      setNewPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Password must be at least 8 characters",
        };
      });
    } else {
      setNewPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.confirmPassword.trim().length === 0) {
      setConfirmNewPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Please enter a valid confirm password",
        };
      });
    } else if (dataForm.confirmPassword !== dataForm.password) {
      setConfirmNewPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Password do not match",
        };
      });
    } else {
      UserChangePassword({
        userId: ctxId?.data.userId,
        currentPassword: dataForm.currentPassword,
        password: dataForm.password,
        confirmPassword: dataForm.confirmPassword,
      }).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            duration: 2000,
            id: "passwordChangedSuccessfuly",
          });
          navigate("/dashboard/profile");
        } else {
          toast.error(res.data.message, {
            duration: 2000,
            id: "passwordChangedSuccessfuly",
          });
        }
      });
      setConfirmNewPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }
  };

  const handleDiscard = () => {
    navigate("/dashboard/profile");
  };

  return (
    <>
      <div className="profile-details">
        <div className="profile-details__title">
          <h2>Change Password</h2>
        </div>
        <div className="profile-details__inputs">
          <div className="change-pw--width">
            <div className="input-error">
              <Input
                htmlFor={"currentPassword"}
                labelText={
                  <>
                    Current Password <RequiredStar />
                  </>
                }
                type={"password"}
                id="currentPassword"
                name="currentPassword"
                onChange={handleChange}
              />
              {!currentPasswordIsValid.valid && (
                <p className="error-message">
                  {currentPasswordIsValid.message}
                </p>
              )}
            </div>
          </div>
          <div className="profile-details__input-content">
            <div className="input-error">
              <Input
                htmlFor={"newPassword"}
                labelText={
                  <>
                    New Password <RequiredStar />
                  </>
                }
                type={"password"}
                id="newPassword"
                name="password"
                onChange={handleChange}
              />
              {!newPasswordIsValid.valid && (
                <p className="error-message">{newPasswordIsValid.message}</p>
              )}
            </div>
            <div className="input-error">
              <Input
                htmlFor={"confirmNewPassword"}
                labelText={
                  <>
                    Confirm New Password <RequiredStar />
                  </>
                }
                type={"password"}
                id="confirmNewPassword"
                name="confirmPassword"
                onChange={handleChange}
              />
              {!confirmNewPasswordIsValid.valid && (
                <p className="error-message">
                  {confirmNewPasswordIsValid.message}
                </p>
              )}
            </div>
          </div>
        </div>
        <p className="change-password__paragraph">Password requirements:</p>
        <ul className="change-password__list">
          <li>Minimum 8 characters long - the more, the better</li>
          <li>At least one uppercase character</li>
          <li>At least one number or symbol character</li>
        </ul>
        <div className="profile-details__buttons">
          <button className="save-changes" onClick={handleSubmit}>
            Save changes
          </button>
          <button className="discard" onClick={handleDiscard}>
            Discard
          </button>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
