import React, { useContext, useState } from "react";
import Input from "../../utilities/Input/Input";
import { LoadingOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";

import "./Login.css";
import { Link } from "react-router-dom";
import { GetToken, webControls } from "../../endpoint";
import { AuthContext } from "../../Context/AuthContext";
import { toast } from "react-hot-toast";
import { Spin } from "antd";
function Login() {
  const ctx = useContext(AuthContext);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const navigate = useNavigate();

  const [preLoader, setPreloader] = useState(false);

  const [dataForm, setDataForm] = useState({
    username: "",
    password: "",
  });

  const [usernameIsValid, setUsernameIsValid] = useState({
    valid: true,
  });

  const [passwordIsValid, setPasswordIsValid] = useState({
    valid: true,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dataForm.username.trim().length === 0) {
      setUsernameIsValid((prev) => {
        return {
          valid: false,
          message: "Username field cannot be empty",
          error: "error-input",
        };
      });
    } else {
      setUsernameIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
    }

    if (dataForm.password.trim().length === 0) {
      setPasswordIsValid((prev) => {
        return {
          valid: false,
          message: "Password field cannot be empty!",
          error: "error-input",
        };
      });
    } else {
      setPasswordIsValid((prev) => {
        return {
          valid: true,
          message: "",
        };
      });
      setPreloader(true);
    }
    GetToken(dataForm).then((res) => {
      localStorage.setItem("accessToken", res.data.token);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      if (res.data.success) {
        setPreloader(false);
        ctx.fetchCurrentClient();
        navigate("/dashboard/home");
      } else {
        setPreloader(false);
        toast.error(res.data.message, {
          duration: 2000,
          id: "errorMessage",
        });
      }
    });
  };

  return (
    <>
      <div className="login-bg">
        <form className="login" onSubmit={handleSubmit} noValidate>
          <span className="login__company">
            {webControls.isCarmax ? "CARMAX" : "CARMAX-KS"}
          </span>
          <h3 className="login__title">
            Welcome to {webControls.isCarmax ? "CARMAX" : "CARMAX-KS"}!
          </h3>
          <p className="login__paragraph">
            Please sign-in to your account and start the adventure
          </p>
          <div className="login__inputs">
            <div className="input-error">
              <Input
                htmlFor={"username"}
                labelText={"Username"}
                type={"text"}
                id="username"
                value={dataForm.username}
                onChange={handleChange}
                name={"username"}
                className={!usernameIsValid.valid && usernameIsValid.error}
                required={false}
              />
              {/* {!emailIsValid.valid && (
                <p className="error-message">{emailIsValid.message}</p>
              )} */}
            </div>
            <div className="input-error">
              <Input
                htmlFor={"password"}
                labelText={"Password"}
                type={"password"}
                id="password"
                value={dataForm.password}
                onChange={handleChange}
                name={"password"}
                className={!passwordIsValid.valid && passwordIsValid.error}
                required={false}
              />
              {/* {!passwordIsValid.valid && (
                <p className="error-message">{passwordIsValid.message}</p>
              )} */}
            </div>
          </div>
          <div className="login__checkbox">
            <Link
              className="login__chekbox__forgot-password"
              to="forgot-password"
            >
              Forgot password?
            </Link>
          </div>
          <button className="login__button">
            {preLoader ? <Spin indicator={antIcon} /> : "Sign in"}
          </button>
        </form>
      </div>
    </>
  );
}

export default Login;
