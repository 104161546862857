import React from "react";
import { Select } from "antd";

import { UserRoleEnum } from "../../enum";
import RequiredStar from "../../utilities/RequiredStar/RequiredStar";

function UserRole({ onChange, className, value, disabled, required = false }) {
  const options = [
    {
      label: "Administrator",
      value: UserRoleEnum.Administrator,
    },
    {
      label: "User",
      value: UserRoleEnum.User,
    },
  ];

  return (
    <>
      <div className="country-content">
        <label className="country-label" htmlFor="roles">
          Role {required && <RequiredStar />}
        </label>
        <Select
          value={value}
          id="roles"
          disabled={disabled}
          options={options}
          defaultValue="Choose Role"
          name="role"
          onChange={(e) => {
            onChange((prev) => {
              return {
                ...prev,
                role: e,
              };
            });
          }}
          className={className}
        />
      </div>
    </>
  );
}

export default UserRole;
