import ClientChart from "./ClientChart/ClientChart";
import ClientTable from "./ClientTable/ClientTable";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import SoldCarsAmount from "./SoldCarsAmount/SoldCarsAmount";
import SoldCarsAveragePriceChart from "./SoldCarsAveragePriceChart/SoldCarsAveragePriceChart";
import WeekAmountStats from "./WeekStats/WeekAmountStats";
import WeekOrdersStats from "./WeekStats/WeekOrdersStats";
import "./Dashboard.css";
import BasedOnAMarkChart from "./CarCharts/BasedOnAMarkChart";
import BasedOnAYearChart from "./CarCharts/BasedOnAYearChart";
import { useEffect, useState } from "react";
import { GetDashboardHeaderData } from "../../endpoint";

function Dashboard() {
  const [dashboardHeaderData, setDashboardHeaderData] = useState({
    activeClients: 0,
    underConsiderationCars: 0,
    confirmedCars: 0,
    receivedCars: 0,
  });
  const [clientsBasedOnCountries, setClientsBasedOnCountries] = useState();

  useEffect(() => {
    GetDashboardHeaderData().then((res) => {
      setDashboardHeaderData((prev) => ({
        ...prev,
        activeClients: res.data.activeClients,
        underConsiderationCars: res.data.underConsiderationCars,
        confirmedCars: res.data.confirmedCars,
        receivedCars: res.data.receivedCars,
      }));
      setClientsBasedOnCountries(res.data.countries);
    });
  }, []);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "4rem" }}>
        <DashboardHeader data={dashboardHeaderData} />
        <ClientTable />
        <ClientChart data={clientsBasedOnCountries} />
        <SoldCarsAveragePriceChart />
        <SoldCarsAmount />
      </div>
    </>
  );
}

export default Dashboard;
