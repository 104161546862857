import React, { useEffect, useState } from "react";
import Preloader from "../../utilities/Preloader/Preloader";
import AuctionCarList from "./AuctionCarList/AuctionCarList";
import { GetCarsByStatusId } from "../../endpoint";
import { CarAuctionEnum } from "../../enum";
import { DatePicker, Empty, Pagination } from "antd";
import Input from "../../utilities/Input/Input";

const RecievedCars = () => {
  const [isReady, setIsReady] = useState(false);
  const [receivedCars, setReceivedCars] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();

  const [searchValues, setSearchValues] = useState({
    companyName: "",
    carName: "",
    fromDate: "",
    toDate: "",
  });

  useEffect(() => {
    GetCarsByStatusId(
      CarAuctionEnum.ReceivedCars,
      currentPage,
      searchValues.companyName,
      searchValues.carName,
      searchValues.fromDate,
      searchValues.toDate
    ).then((res) => {
      setReceivedCars(res.data.auctionCars);
      setTotalItems(res.data.receivedCars);
      setIsReady(true);
    });
  }, [currentPage, searchValues]);

  const handlePaginationChange = (page) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setCurrentPage(page);
  };

  return (
    <div className="profile-details">
      <div className="profile-details__title">
        <h2>Received Cars</h2>
        <div className="profile-details__input-content search-content search-content--width cars">
          <Input
            htmlFor={"search"}
            placeholder={""}
            labelText={"Client Name"}
            type={"text"}
            id="companyName"
            value={searchValues?.companyName}
            onChange={(e) => {
              setSearchValues((prev) => ({
                ...prev,
                companyName: e.target.value,
              }));
            }}
          />
          <Input
            htmlFor={"search"}
            placeholder={""}
            labelText={"Car name/Brand"}
            type={"text"}
            id="Carname"
            value={searchValues?.carName}
            onChange={(e) => {
              setSearchValues((prev) => ({
                ...prev,
                carName: e.target.value,
              }));
            }}
          />
          <div className="form-input">
            <label className="input-label">From Date</label>
            <DatePicker
              className="input"
              onChange={(e) => {
                const selectedDate = e?.format("YYYY-MM-DD");
                setSearchValues((prev) => ({
                  ...prev,
                  fromDate: selectedDate,
                }));
              }}
              style={{ padding: "9.136px 1rem" }}
            />
          </div>
          <div className="form-input">
            <label className="input-label">To Date</label>
            <DatePicker
              className="input"
              onChange={(e) => {
                const selectedDate = e?.format("YYYY-MM-DD");
                setSearchValues((prev) => ({
                  ...prev,
                  toDate: selectedDate,
                }));
              }}
              style={{ padding: "9.136px 1rem" }}
            />
          </div>
        </div>
        <Preloader isReady={isReady}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2.5rem",
                marginTop: "3rem",
              }}
              className="bids-list"
            >
              {receivedCars?.length > 0 && (
                <>
                  {receivedCars?.map((data) => {
                    return (
                      <div key={data.id}>
                        <AuctionCarList
                          id={data.id}
                          hp={data.hp}
                          km={data.km}
                          name={data.name}
                          petrol={data.petrol}
                          transmission={data.transmission}
                          registrationDate={data.registrationDate}
                          finishingDateTime={data.finishingDateTime}
                          registrationNumber={data.registrationNumber}
                          country={data.country}
                          vehicleCategory={data.vehicleCategory}
                          isFavorite={data.isFavorite}
                          src={data.carMainImage}
                          setCars={() => {}}
                          setCarsNo={() => {}}
                          bidAmount={data?.bidAmount}
                          showInvoiceButton={true}
                          startingPrice={data?.startingPrice}
                          clientName={data?.clientName}
                          registrationMonth={data.registrationMonth}
                        />
                      </div>
                    );
                  })}
                  <Pagination
                    showSizeChanger={false}
                    defaultCurrent={currentPage}
                    total={totalItems}
                    onChange={handlePaginationChange}
                  />
                </>
              )}

              {receivedCars?.length == 0 && (
                <>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                      height: 60,
                    }}
                    description={<span>No auctions have been received.</span>}
                  ></Empty>
                </>
              )}
            </div>
          </div>
        </Preloader>
      </div>
    </div>
  );
};

export default RecievedCars;
