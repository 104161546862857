import React, { useEffect, useState } from "react";
import "./ClientCars.css";
import passatB8 from "../../assets/images/passatb8.jpg";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import "bootstrap/dist/css/bootstrap.css";
import { Table } from "reactstrap";
import {
  GetClientsDetails,
  GetConfirmedReceivedCarsByClientId,
} from "../../endpoint";
import { Empty } from "antd";
import { CarAuctionEnum } from "../../enum";
import AuctionCarList from "../CarsList/AuctionCarList/AuctionCarList";
import Preloader from "../../utilities/Preloader/Preloader";

function ClientCars() {
  const { clientId } = useParams();
  const [clientDetails, setClientDetails] = useState({});
  const [onPaymentSection, setOnPaymentSection] = useState(true);
  const [sectionPageName, setSectionPageName] = useState("payment");
  const [receivedSectionHide, setReceivedSectionHide] = useState(false);
  const [auctionCars, setAuctionsCars] = useState([]);
  const [activeKey, setactiveKey] = useState(CarAuctionEnum.ConfirmedCars);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    GetClientsDetails(clientId).then((res) => {
      setClientDetails(res.data);
    });
  }, [clientId]);

  useEffect(() => {
    GetConfirmedReceivedCarsByClientId(activeKey, clientId).then((res) => {
      setAuctionsCars(res.data.auctionCars);
      setIsReady(true);
    });
  }, [clientId, activeKey]);

  const handlePaymentSectionChange = () => {
    setOnPaymentSection(false);
    setSectionPageName("documents");
  };

  const handleDocumentSectionChange = () => {
    setOnPaymentSection(true);
    setSectionPageName("payment");
  };

  const onChange = (key) => {
    setactiveKey(key);
  };

  return (
    <>
      {!receivedSectionHide ? (
        <>
          <>
            <Table responsive>
              <thead>
                <tr className="table-titles">
                  <th>Company</th>
                  <th>Registration Number</th>
                  <th>First and Last Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Register Date</th>
                </tr>
              </thead>
              <tbody>
                <tr className="tr-fontSize">
                  <td>
                    <span className="align-middle ">{clientDetails.name}</span>
                  </td>
                  <td>{clientDetails.companyRegistrationNumber}</td>
                  <td>
                    {clientDetails.firstName} {clientDetails.lastName}
                  </td>
                  <td>{clientDetails.email}</td>
                  <td>{clientDetails.mobileNo}</td>
                  <td>{clientDetails?.createdOn?.substring(0, 10)}</td>
                </tr>
              </tbody>
            </Table>

            <Preloader isReady={isReady}>
              <div
                className="client-car-container"
                style={{ marginTop: "2rem" }}
              >
                <div className="payment-and-documents__nav">
                  <Link
                    className={`payment-and-documents__nav-link ${
                      activeKey == CarAuctionEnum.ConfirmedCars ? "active" : ""
                    }`}
                    onClick={() => onChange(CarAuctionEnum.ConfirmedCars)}
                  >
                    Confirmed Cars
                  </Link>
                  <Link
                    className={`payment-and-documents__nav-link ${
                      activeKey == CarAuctionEnum.ReceivedCars ? "active" : ""
                    }`}
                    onClick={() => onChange(CarAuctionEnum.ReceivedCars)}
                  >
                    Received Cars
                  </Link>
                </div>
                <div
                  className="bids-list"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2.5rem",
                    marginTop: "1rem",
                  }}
                >
                  {auctionCars?.length > 0 &&
                    auctionCars?.map((data) => {
                      return (
                        <AuctionCarList
                          id={data.id}
                          hp={data.hp}
                          km={data.km}
                          name={data.name}
                          petrol={data.petrol}
                          transmission={data.transmission}
                          registrationDate={data.registrationDate}
                          finishingDateTime={data.finishingDateTime}
                          registrationNumber={data.registrationNumber}
                          country={data.country}
                          vehicleCategory={data.vehicleCategory}
                          isFavorite={data.isFavorite}
                          src={data.carMainImage}
                          setCars={setAuctionsCars}
                          setCarsNo={() => {}}
                          bidAmount={data?.bidAmount}
                          showInvoiceButton={true}
                          showConfirmButton={
                            activeKey == CarAuctionEnum.ConfirmedCars && true
                          }
                          cars={auctionCars}
                          startingPrice={data?.startingPrice}
                          clientName={data?.clientName}
                          registrationMonth={data.registrationMonth}
                        />
                      );
                    })}
                  {auctionCars?.length == 0 && (
                    <>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        imageStyle={{
                          height: 60,
                        }}
                        description={
                          <span>
                            {activeKey == CarAuctionEnum.ConfirmedCars
                              ? "No confirmed cars are associated with this client."
                              : "No received cars are associated with this client."}
                          </span>
                        }
                      ></Empty>
                    </>
                  )}
                </div>
              </div>
            </Preloader>
          </>
        </>
      ) : (
        <>
          <div className="wining-car-section">
            <div className="wining-car" style={{ width: "60%" }}>
              <div className="wining-car__img">
                <img src={passatB8} alt="" />
              </div>
              <div className="wining-car__content">
                <span>ID: 9593086</span>
                <h2>Volkswagen PASSAT - 2.0 Tdi Scr 150 km Dsg R line</h2>
                <span>Picked up</span>
                <div className="wining-car__informations">
                  <div className="wining-car__informations-content">
                    <h6>127.429km</h6>
                    <h6>2019-04-23</h6>
                    <h6 style={{ display: "flex", gap: "5px" }}>
                      <ReactCountryFlag
                        countryCode="DK"
                        svg
                        style={{ width: "14px" }}
                      />
                      Denmark
                    </h6>
                  </div>
                  <div className="wining-car__informations-content">
                    <h6>1968 cc 150 AG (110 kW)</h6>
                    <h6>Diesel</h6>
                    <h6>Automatic</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="payment-and-documents">
            <div className="payment-and-documents__nav">
              <Link
                className={`payment-and-documents__nav-link ${
                  sectionPageName === "payment" ? "active" : ""
                }`}
                onClick={handleDocumentSectionChange}
              >
                Payment
              </Link>
              <Link
                className={`payment-and-documents__nav-link ${
                  sectionPageName === "documents" ? "active" : ""
                }`}
                onClick={handlePaymentSectionChange}
              >
                Documents
              </Link>
            </div>
            {onPaymentSection ? (
              <>
                <div className="payment">
                  <div className="payment__content payment__content--navbar">
                    <h4>Description</h4>
                    <h6>Amount, €</h6>
                    <h6>Payment term</h6>
                    <h6>Paid</h6>
                  </div>
                  <div className="payment__content">
                    <h4>Vehicle</h4>
                    <h6>€ 11,500.00</h6>
                    <h6>2023-02-28</h6>
                    <h6>€ 11,500.00</h6>
                  </div>
                  <div className="payment__content">
                    <h4>Bank fee</h4>
                    <h6>€ 390.00</h6>
                    <h6>2023-02-28</h6>
                    <h6>€ 360.00</h6>
                  </div>
                  <div className="payment__content">
                    <h4>Operator fee</h4>
                    <h6>€ 389.00</h6>
                    <h6>2023-02-28</h6>
                    <h6>€ 389.00</h6>
                  </div>
                  <div className="payment__content">
                    <h4>Auction fee</h4>
                    <h6>€ 119.00</h6>
                    <h6>2023-02-28</h6>
                    <h6>€ 119.00</h6>
                  </div>
                  <div className="payment__content">
                    <h4>Transportation to the HUB-DE</h4>
                    <h6>€ 400.00</h6>
                    <h6>2023-02-28</h6>
                    <h6>€ 400.00</h6>
                  </div>
                  <div className="payment__content">
                    <h4 className="payment__content-bold-heading">
                      Total, exclusive of VAT
                    </h4>
                    <h6 className="payment__content-bold-heading">
                      € 12,768.00
                    </h6>
                    <h6>-</h6>
                    <h6 className="payment__content-bold-heading">
                      € 12,768.00
                    </h6>
                  </div>
                  <div className="payment__content">
                    <h4>Fee for parking of the HUB-DE area</h4>
                    <h6>-</h6>
                    <h6>-</h6>
                    <h6>-</h6>
                  </div>
                  <div className="payment__content">
                    <h4 className="payment__content-bold-heading">
                      Total, exclusive of VAT
                    </h4>
                    <h6 className="payment__content-bold-heading">
                      € 12,768.00
                    </h6>
                    <h6>-</h6>
                    <h6 className="payment__content-bold-heading">
                      € 12,768.00
                    </h6>
                  </div>
                  <div className="payment__content payment__content--done">
                    <h4 className="payment__content-bold-heading">Total</h4>
                    <h6 className="payment__content-bold-heading">
                      € 12,768.00
                    </h6>
                    <h6>-</h6>
                    <h6 className="payment__content-bold-heading">
                      € 12,768.00
                    </h6>
                  </div>
                  <div className="payment__content payment__content--done">
                    <h4 className="payment__content-bold-heading">Debt</h4>
                    <h5 style={{ fontWeight: "600", color: "#d00" }}>€ 0.00</h5>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="documents">
                  <div className="documents__content">
                    <span>Car invoice</span>
                    <a href=" #">
                      Download{" "}
                      <BsFillFileEarmarkPdfFill className="documents__content-icon" />
                    </a>
                  </div>
                  <div className="documents__content">
                    <span>Vehicle registration certificate</span>
                    <a href=" #">
                      Download{" "}
                      <BsFillFileEarmarkPdfFill className="documents__content-icon" />
                    </a>
                  </div>
                  <div className="documents__content">
                    <span>Delivery Confirmation</span>
                    <a href="# ">
                      Download{" "}
                      <BsFillFileEarmarkPdfFill className="documents__content-icon" />
                    </a>
                  </div>
                  <div className="documents__content">
                    <span>Pick Up</span>
                    <a href=" #">
                      Download{" "}
                      <BsFillFileEarmarkPdfFill className="documents__content-icon" />
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ClientCars;
